import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty, startCase } from "lodash";
import moment from "moment";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";

import { RestrictPages } from "../helpers";
import { LoaderContext } from "../loader"
import DashboardLayout from "../layouts/dashboard-layout";

export default function Rules(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const authProps = useSelector(state => state.auth);
    const ruleProps = useSelector(state => state.rules);
    const [rules, setRules] = useState({});

    const { setLoading } = useContext(LoaderContext);

    const limit = 50;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        RestrictPages(history, authProps?.plan_details, 'FREE', '/dashboard', 'redirect');

        FetchRules();
    }, [page]);

    useEffect(() => {
        if (!isEmpty(ruleProps)) {
            setRules(ruleProps.results);
            setTotal(ruleProps.total);
        } else {
            setRules([]);
            setTotal(0);
        }
    }, [ruleProps]);

    const FetchRules = () => {
        dispatch({
            type: "FETCH_RULES",
            payload: {
                page: page - 1,
                limit: limit
            }
        });
    }

    const DeleteRule = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            html: 'This will permanently remove the rule rules and remove it from the associated products.',
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "OK"
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);

                dispatch({
                    type: "DELETE_RULE",
                    payload: {
                        id: id
                    }
                });

                setTimeout(() => {
                    FetchRules();
                    setLoading(false);
                }, 1000);
            }
        });
    }

    return (
        <DashboardLayout {...props}>
            <section>
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Rules </h1>
                        <p className="is-size-5m6 mb-4">Create Upsell Rule, Cross Sell and Post Purchase Rules here for products.</p>
                    </div>
                    <div>
                        <button className="button is-primary with-shadow mr-2" onClick={() => history.push('/rule-settings')}>Rule Settings</button>
                        <button className="button is-primary with-shadow" onClick={() => history.push('/rules/create')}>Create Rule</button>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline mt-2 mb-0">
                <div className="column is-12 pb-0">
                    <div className="box table-container">
                        <table className="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Trigger</th>
                                    <th>Type</th>
                                    <th>Created On</th>
                                    <th>Updated On</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !isEmpty(rules) && Object.values(rules).map((rule, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{rule?.name}</td>
                                                <td>
                                                    {rule?.trigger === "add-to-cart" ? <div className="tag is-light is-success">Add To Cart</div> : ""}
                                                    {rule?.trigger === "before-checkout" ? <div className="tag is-light is-danger">Before Checkout</div> : ""}
                                                    {rule?.trigger === "shopify-post-purchase" ? <div className="tag is-light is-primary">Post Purchase</div> : ""}
                                                </td>
                                                <td>
                                                    {rule?.type === "upsell" ? <div className="tag is-light is-success">Upsell</div> : ""}
                                                    {rule?.type === "cross-sell" ? <div className="tag is-light is-danger">Cross Sell</div> : ""}
                                                </td>
                                                <td>{moment(rule?.created_at).format('DD MMM, YYYY')}</td>
                                                <td>{moment(rule?.updated_at).format('DD MMM, YYYY')}</td>
                                                <td>
                                                    <button className="button is-primary mr-1" onClick={() => history.push(`/rules/${rule?._id}`)}>Edit Rule</button>
                                                    <button className="button is-danger" onClick={() => DeleteRule(rule?._id)}>Delete Rule</button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {
                            isEmpty(rules) ? <p className="pt-0 pb-5 has-text-centered is-fullwidth box-content">No rules found</p> : ""
                        }
                    </div>
                </div>
            </div>

            <div className="is-flex is-align-items-center is-justify-content-space-between mt-4">
                <div>Showing <b>{rules.length}</b> of <b>{total}</b> rules</div>
                <Pagination activePage={page} itemsCountPerPage={limit} totalItemsCount={total} pageRangeDisplayed={5} onChange={(e) => setPage(e)} />
            </div>
        </DashboardLayout>
    )
}