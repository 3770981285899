import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

function Header() {
    const history = useHistory();
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);

    let location = new URLSearchParams(useLocation().search);
    let chargeID = location.get("charge_id");

    useEffect(() => {
        var connections = document.querySelectorAll('.connection');

        for (let i = 0; i < connections.length; i++) {
            connections[i].parentNode.removeChild(connections[i]);
        }
    }, []);

    useEffect(() => {
        if (chargeID !== null) {
            dispatch({
                type: "SET_RECURRING_CHARGE",
                payload: {
                    id: chargeID
                }
            });

            history.push('/dashboard');
        }
    }, [chargeID]);

    function ToggleSidebar() {
        let sidebar = document.getElementsByClassName('sidebar')[0];
        if (sidebar.classList.contains('show')) {
            sidebar.classList.remove('show');
        } else {
            sidebar.classList.add('show');
        }
    }

    function UpgradePlan(type) {
        history.push(`/upgrade-plan?type=${type}`);
    }

    function CancelPlan() {
        Swal.fire({
            icon: "error",
            title: `Are you sure you want to cancel the plan ?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel"
        }).then((result) => {
            if (result.isConfirmed) {
                history.push('/cancel-plan');
            }
        });
    }

    return (
        <div className="header py-2 px-4 has-background-white">
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="is-flex is-justify-content-space-between w-full is-align-items-center header-minimal">
                    <a role="button" className="navbar-burger burger ml-0" data-target="navMenu" aria-label="menu" aria-expanded="false" onClick={() => ToggleSidebar()}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                </div>
                <div id="navMenu" className="navbar-menu">
                    <div className="navbar-start">
                        <div className="is-flex is-align-items-center">
                            {
                                authProps?.plan_details === undefined ? <span className="is-size-5m6">Purchase a plan to unlock the app. <a className="has-text-danger has-text-weight-semibold is-clickable" onClick={() => UpgradePlan('FREE')}><u>Purchase a Plan Now</u></a></span> : ""
                            }
                            {
                                authProps?.plan_details?.name === "FREE" ? <span className="is-size-5m6">You are currently on the {authProps?.plan_details?.name} Plan. <a className="has-text-danger has-text-weight-semibold is-clickable" onClick={() => UpgradePlan('ULTIMATE')}><u>Upgrade to Ultimate Plan</u></a></span> : ""
                            }
                            {
                                authProps?.plan_details?.name === "ULTIMATE" ? <span className="is-size-5m6">You are currently on the {authProps?.plan_details?.name} Plan. <a className="has-text-danger has-text-weight-semibold is-clickable" onClick={() => CancelPlan()}><u>Cancel Plan</u></a></span> : ""
                            }
                        </div>
                    </div>
                    <div className="navbar-end">
                        <div className="is-flex">
                            <div className="navbar-item has-dropdown is-hoverable">
                                <div className="navbar-link">
                                    <div className="loggedin-user is-flex">
                                        <span className="user-name-character is-block has-text-white">{authProps?.firstname.charAt(0).toUpperCase()}</span>
                                    </div>
                                </div>
                                <div className="navbar-dropdown is-right loggedin-user-dd">
                                    <a className="navbar-item pr-3 has-text-primary" onClick={() => history.push('/billing')}> <span className="icon-text"><span className="icon"><i className="fa fa-money"></i></span><span>Billing</span></span> </a>
                                    <a className="navbar-item pr-3 has-text-danger" onClick={() => history.push('/logout')}> <span className="icon-text"><span className="icon"><i className="fa fa-power-off"></i></span><span>Logout</span></span> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header;