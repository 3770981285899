import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";

import Spinner from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function CancelPlan() {
    const history = useHistory();
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);

    useEffect(() => {
        dispatch({
            type: "CANCEL_RECURRING_CHARGE",
            payload: {}
        });
    }, []);

    useEffect(() => {
        if (!isEmpty(authProps)) {
            history.push('/dashboard');
        }
    }, [authProps]);

    return (
        <div className="loaderContainer">
            <Spinner type="Rings" color="Black" height={150} width={150} visible={true} />
        </div>
    );
}

export default CancelPlan;