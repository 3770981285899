import { takeLatest, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { Storage } from '../helpers';

//     ______     __       __       ____        __         
//    / ____/__  / /______/ /_     / __ \__  __/ /__  _____
//   / /_  / _ \/ __/ ___/ __ \   / /_/ / / / / / _ \/ ___/
//  / __/ /  __/ /_/ /__/ / / /  / _, _/ /_/ / /  __(__  ) 
// /_/    \___/\__/\___/_/ /_/  /_/ |_|\__,_/_/\___/____/  
                                                        
export function* watchFetchRules() {
    yield takeLatest("FETCH_RULES", trackFetchRules);
}

function* trackFetchRules(sagaData) {
    try {
        var response = yield call(callFetchRules, sagaData.payload);

        if (response) {
            yield put({
                type: "FETCH_RULES_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "FETCH_RULES_ERROR",
            response: err
        });
    }
}

function callFetchRules(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/store/fetch-rules", {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ______     __       __       ____        __   
//    / ____/__  / /______/ /_     / __ \__  __/ /__ 
//   / /_  / _ \/ __/ ___/ __ \   / /_/ / / / / / _ \
//  / __/ /  __/ /_/ /__/ / / /  / _, _/ /_/ / /  __/
// /_/    \___/\__/\___/_/ /_/  /_/ |_|\__,_/_/\___/ 
                                                  
export function* watchFetchRule() {
    yield takeLatest("FETCH_RULE", trackFetchRule);
}

function* trackFetchRule(sagaData) {
    try {
        var response = yield call(callFetchRule, sagaData.payload);

        if (response) {
            yield put({
                type: "FETCH_RULE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "FETCH_RULE_ERROR",
            response: err
        });
    }
}

function callFetchRule(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/store/fetch-rule", {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ______                __          ____        __   
//   / ____/_______  ____ _/ /____     / __ \__  __/ /__ 
//  / /   / ___/ _ \/ __ `/ __/ _ \   / /_/ / / / / / _ \
// / /___/ /  /  __/ /_/ / /_/  __/  / _, _/ /_/ / /  __/
// \____/_/   \___/\__,_/\__/\___/  /_/ |_|\__,_/_/\___/ 
                                                      
export function* watchCreateRule() {
    yield takeLatest("CREATE_RULE", trackCreateRule);
}

function* trackCreateRule(sagaData) {
    try {
        var response = yield call(callCreateRule, sagaData.payload);

        if (response) {
            yield put({
                type: "CREATE_RULE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "CREATE_RULE_ERROR",
            response: err
        });
    }
}

function callCreateRule(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/store/create-rule", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    __  __          __      __          ____        __   
//   / / / /___  ____/ /___ _/ /____     / __ \__  __/ /__ 
//  / / / / __ \/ __  / __ `/ __/ _ \   / /_/ / / / / / _ \
// / /_/ / /_/ / /_/ / /_/ / /_/  __/  / _, _/ /_/ / /  __/
// \____/ .___/\__,_/\__,_/\__/\___/  /_/ |_|\__,_/_/\___/ 
//     /_/                                                 

export function* watchUpdateRule() {
    yield takeLatest("UPDATE_RULE", trackUpdateRule);
}

function* trackUpdateRule(sagaData) {
    try {
        var response = yield call(callUpdateRule, sagaData.payload);

        if (response) {
            yield put({
                type: "UPDATE_RULE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "UPDATE_RULE_ERROR",
            response: err
        });
    }
}

function callUpdateRule(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/store/update-rule", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____       __     __          ____        __   
//    / __ \___  / /__  / /____     / __ \__  __/ /__ 
//   / / / / _ \/ / _ \/ __/ _ \   / /_/ / / / / / _ \
//  / /_/ /  __/ /  __/ /_/  __/  / _, _/ /_/ / /  __/
// /_____/\___/_/\___/\__/\___/  /_/ |_|\__,_/_/\___/ 
                                                   
export function* watchDeleteRule() {
    yield takeLatest("DELETE_RULE", trackDeleteRule);
}

function* trackDeleteRule(sagaData) {
    try {
        var response = yield call(callDeleteRule, sagaData.payload);

        if (response) {
            yield put({
                type: "DELETE_RULE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "DELETE_RULE_ERROR", 
            response: err
        });
    }
}

function callDeleteRule(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/store/delete-rule", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    __  __          __      __          ____        __        _____      __  __  _                 
//   / / / /___  ____/ /___ _/ /____     / __ \__  __/ /__     / ___/___  / /_/ /_(_)___  ____ ______
//  / / / / __ \/ __  / __ `/ __/ _ \   / /_/ / / / / / _ \    \__ \/ _ \/ __/ __/ / __ \/ __ `/ ___/
// / /_/ / /_/ / /_/ / /_/ / /_/  __/  / _, _/ /_/ / /  __/   ___/ /  __/ /_/ /_/ / / / / /_/ (__  ) 
// \____/ .___/\__,_/\__,_/\__/\___/  /_/ |_|\__,_/_/\___/   /____/\___/\__/\__/_/_/ /_/\__, /____/  
//     /_/                                                                             /____/        

export function* watchUpdateRuleSettings() {
    yield takeLatest("UPDATE_RULE_SETTINGS", trackUpdateRuleSettings);
}

function* trackUpdateRuleSettings(sagaData) {
    try {
        var response = yield call(callUpdateRuleSettings, sagaData.payload);

        if (response) {
            yield put({
                type: "UPDATE_RULE_SETTINGS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "UPDATE_RULE_SETTINGS_ERROR",
            response: err
        });
    }
}

function callUpdateRuleSettings(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/store/update-rule-settings", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     __    _      __     ___    __                    __                     __   ______           __ 
//    / /   (_)____/ /_   /   |  / /_  ____ _____  ____/ /___  ____  ___  ____/ /  / ____/___ ______/ /_
//   / /   / / ___/ __/  / /| | / __ \/ __ `/ __ \/ __  / __ \/ __ \/ _ \/ __  /  / /   / __ `/ ___/ __/
//  / /___/ (__  ) /_   / ___ |/ /_/ / /_/ / / / / /_/ / /_/ / / / /  __/ /_/ /  / /___/ /_/ / /  / /_  
// /_____/_/____/\__/  /_/  |_/_.___/\__,_/_/ /_/\__,_/\____/_/ /_/\___/\__,_/   \____/\__,_/_/   \__/  
                                                                                                     
export function* watchListAbandonedCart() {
    yield takeLatest("LIST_ABANDONED_CART", trackListAbandonedCart);
}

function* trackListAbandonedCart(sagaData) {
    try {
        var response = yield call(callListAbandonedCart, sagaData.payload);

        if (response) {
            yield put({
                type: "LIST_ABANDONED_CART_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "LIST_ABANDONED_CART_ERROR", 
            response: err
        });
    }
}

function callListAbandonedCart(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/store/list-abandoned-cart", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____                                    ___    __                    __                     __   ______           __ 
//    / __ \___  ____ ___  ____ _   _____     /   |  / /_  ____ _____  ____/ /___  ____  ___  ____/ /  / ____/___ ______/ /_
//   / /_/ / _ \/ __ `__ \/ __ \ | / / _ \   / /| | / __ \/ __ `/ __ \/ __  / __ \/ __ \/ _ \/ __  /  / /   / __ `/ ___/ __/
//  / _, _/  __/ / / / / / /_/ / |/ /  __/  / ___ |/ /_/ / /_/ / / / / /_/ / /_/ / / / /  __/ /_/ /  / /___/ /_/ / /  / /_  
// /_/ |_|\___/_/ /_/ /_/\____/|___/\___/  /_/  |_/_.___/\__,_/_/ /_/\__,_/\____/_/ /_/\___/\__,_/   \____/\__,_/_/   \__/  
                                                                                                                         
export function* watchRemoveAbandonedCart() {
    yield takeLatest("REMOVE_ABANDONED_CART", trackRemoveAbandonedCart);
}

function* trackRemoveAbandonedCart(sagaData) {
    try {
        var response = yield call(callRemoveAbandonedCart, sagaData.payload);

        if (response) {
            yield put({
                type: "REMOVE_ABANDONED_CART_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "REMOVE_ABANDONED_CART_ERROR", 
            response: err
        });
    }
}

function callRemoveAbandonedCart(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/store/remove-abandoned-cart", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    __  __          __      __          ______                _ __   _____      __  __  _                 
//   / / / /___  ____/ /___ _/ /____     / ____/___ ___  ____ _(_) /  / ___/___  / /_/ /_(_)___  ____ ______
//  / / / / __ \/ __  / __ `/ __/ _ \   / __/ / __ `__ \/ __ `/ / /   \__ \/ _ \/ __/ __/ / __ \/ __ `/ ___/
// / /_/ / /_/ / /_/ / /_/ / /_/  __/  / /___/ / / / / / /_/ / / /   ___/ /  __/ /_/ /_/ / / / / /_/ (__  ) 
// \____/ .___/\__,_/\__,_/\__/\___/  /_____/_/ /_/ /_/\__,_/_/_/   /____/\___/\__/\__/_/_/ /_/\__, /____/  
//     /_/                                                                                    /____/        

export function* watchUpdateEmailSettings() {
    yield takeLatest("UPDATE_EMAIL_SETTINGS", trackUpdateEmailSettings);
}

function* trackUpdateEmailSettings(sagaData) {
    try {
        var response = yield call(callUpdateEmailSettings, sagaData.payload);

        if (response) {
            yield put({
                type: "UPDATE_EMAIL_SETTINGS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "UPDATE_EMAIL_SETTINGS_ERROR",
            response: err
        });
    }
}

function callUpdateEmailSettings(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/store/update-email-settings", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//   ______     _                          ______          __     ______                _ __
//  /_  __/____(_)___ _____ ____  _____   /_  __/__  _____/ /_   / ____/___ ___  ____ _(_) /
//   / / / ___/ / __ `/ __ `/ _ \/ ___/    / / / _ \/ ___/ __/  / __/ / __ `__ \/ __ `/ / / 
//  / / / /  / / /_/ / /_/ /  __/ /       / / /  __(__  ) /_   / /___/ / / / / / /_/ / / /  
// /_/ /_/  /_/\__, /\__, /\___/_/       /_/  \___/____/\__/  /_____/_/ /_/ /_/\__,_/_/_/   
//            /____//____/                                                                  

export function* watchTriggerTestEmail() {
    yield takeLatest("TRIGGER_TEST_EMAIL", trackTriggerTestEmail);
}

function* trackTriggerTestEmail(sagaData) {
    try {
        var response = yield call(callTriggerTestEmail, sagaData.payload);

        if (response) {
            yield put({
                type: "TRIGGER_TEST_EMAIL_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "TRIGGER_TEST_EMAIL_ERROR",
            response: err
        });
    }
}

function callTriggerTestEmail(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/store/trigger-test-email", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//   ______     _                          __  __                  ______                _ __
//  /_  __/____(_)___ _____ ____  _____   / / / /_______  _____   / ____/___ ___  ____ _(_) /
//   / / / ___/ / __ `/ __ `/ _ \/ ___/  / / / / ___/ _ \/ ___/  / __/ / __ `__ \/ __ `/ / / 
//  / / / /  / / /_/ / /_/ /  __/ /     / /_/ (__  )  __/ /     / /___/ / / / / / /_/ / / /  
// /_/ /_/  /_/\__, /\__, /\___/_/      \____/____/\___/_/     /_____/_/ /_/ /_/\__,_/_/_/   
//            /____//____/                                                                   

export function* watchTriggerUserEmail() {
    yield takeLatest("TRIGGER_USER_EMAIL", trackTriggerUserEmail);
}

function* trackTriggerUserEmail(sagaData) {
    try {
        var response = yield call(callTriggerUserEmail, sagaData.payload);

        if (response) {
            yield put({
                type: "TRIGGER_USER_EMAIL_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "TRIGGER_USER_EMAIL_ERROR",
            response: err
        });
    }
}

function callTriggerUserEmail(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/store/trigger-user-email", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}