import { takeLatest, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { Storage } from '../helpers';

//     ______     __       __       ____                 __           __      
//    / ____/__  / /______/ /_     / __ \_________  ____/ /_  _______/ /______
//   / /_  / _ \/ __/ ___/ __ \   / /_/ / ___/ __ \/ __  / / / / ___/ __/ ___/
//  / __/ /  __/ /_/ /__/ / / /  / ____/ /  / /_/ / /_/ / /_/ / /__/ /_(__  ) 
// /_/    \___/\__/\___/_/ /_/  /_/   /_/   \____/\__,_/\__,_/\___/\__/____/  

export function* watchFetchProducts() {
    yield takeLatest("FETCH_PRODUCTS", trackFetchProducts);
}

function* trackFetchProducts(sagaData) {
    try {
        var response = yield call(callFetchProducts, sagaData.payload);

        if (response) {
            yield put({
                type: "FETCH_PRODUCTS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "FETCH_PRODUCTS_ERROR",
            response: err
        });
    }
}

function callFetchProducts(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/store/fetch-products", {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ___       __    __   ____                 __           __      
//    /   | ____/ /___/ /  / __ \_________  ____/ /_  _______/ /______
//   / /| |/ __  / __  /  / /_/ / ___/ __ \/ __  / / / / ___/ __/ ___/
//  / ___ / /_/ / /_/ /  / ____/ /  / /_/ / /_/ / /_/ / /__/ /_(__  ) 
// /_/  |_\__,_/\__,_/  /_/   /_/   \____/\__,_/\__,_/\___/\__/____/  

export function* watchAddProducts() {
    yield takeLatest("ADD_PRODUCTS", trackAddProducts);
}

function* trackAddProducts(sagaData) {
    try {
        var response = yield call(callAddProducts, sagaData.payload);

        if (response) {
            yield put({
                type: "ADD_PRODUCTS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADD_PRODUCTS_ERROR",
            response: err
        });
    }
}

function callAddProducts(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/store/add-products", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____                                    ____                 __           __ 
//    / __ \___  ____ ___  ____ _   _____     / __ \_________  ____/ /_  _______/ /_
//   / /_/ / _ \/ __ `__ \/ __ \ | / / _ \   / /_/ / ___/ __ \/ __  / / / / ___/ __/
//  / _, _/  __/ / / / / / /_/ / |/ /  __/  / ____/ /  / /_/ / /_/ / /_/ / /__/ /_  
// /_/ |_|\___/_/ /_/ /_/\____/|___/\___/  /_/   /_/   \____/\__,_/\__,_/\___/\__/  

export function* watchRemoveProduct() {
    yield takeLatest("REMOVE_PRODUCT", trackRemoveProduct);
}

function* trackRemoveProduct(sagaData) {
    try {
        var response = yield call(callRemoveProduct, sagaData.payload);

        if (response) {
            yield put({
                type: "REMOVE_PRODUCT_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "REMOVE_PRODUCT_ERROR",
            response: err
        });
    }
}

function callRemoveProduct(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/store/remove-product", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ___       __    __   ____        __        __           ____                 __           __      
//    /   | ____/ /___/ /  / __ \__  __/ /__     / /_____     / __ \_________  ____/ /_  _______/ /______
//   / /| |/ __  / __  /  / /_/ / / / / / _ \   / __/ __ \   / /_/ / ___/ __ \/ __  / / / / ___/ __/ ___/
//  / ___ / /_/ / /_/ /  / _, _/ /_/ / /  __/  / /_/ /_/ /  / ____/ /  / /_/ / /_/ / /_/ / /__/ /_(__  ) 
// /_/  |_\__,_/\__,_/  /_/ |_|\__,_/_/\___/   \__/\____/  /_/   /_/   \____/\__,_/\__,_/\___/\__/____/  
                                                                                                                                                                  
export function* watchAddRuleToProducts() {
    yield takeLatest("ADD_RULE_TO_PRODUCTS", trackAddRuleToProducts);
}

function* trackAddRuleToProducts(sagaData) {
    try {
        var response = yield call(callAddRuleToProducts, sagaData.payload);

        if (response) {
            yield put({
                type: "ADD_RULE_TO_PRODUCTS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADD_RULE_TO_PRODUCTS_ERROR",
            response: err
        });
    }
}

function callAddRuleToProducts(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/store/add-rule-to-products", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}