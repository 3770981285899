// React
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// Redux, Saga, Reducer 
import { createStore, applyMiddleware } from 'redux';
import { logger } from 'redux-logger';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducer';
import saga from "./saga";

// Global Loader
import { LoaderContext } from './loader';

// User Pages
import InstallApp from './pages/installation/install-app';
import InstallAppCallBack from './pages/installation/install-app-callback';
import Dashboard from './pages/dashboard';
import Products from './pages/products';
import Rules from './pages/rules';
import Billing from './pages/billing';
import AbandonedCart from './pages/abandoned-cart';
import RuleSettings from './pages/rule-settings';
import CartSettings from './pages/cart-settings';
import EmailSettings from './pages/email-settings';
import RuleDetails from './pages/rule-details';
import UpgradePlan from './pages/upgrade-plan';
import CancelPlan from './pages/cancel-plan';

// Store, Reducer and Saga Integration
import { throttle } from 'lodash';
import { State, Storage } from './helpers';

const persistedState = State.Load();
const sagaMiddleware = createSagaMiddleware();
var store = "";
if (process.env.NODE_ENV === "development") {
  store = createStore(rootReducer, persistedState, applyMiddleware(sagaMiddleware, logger));
} else {
  store = createStore(rootReducer, persistedState, applyMiddleware(sagaMiddleware));
}

store.subscribe(throttle(() => {
  State.Save({
    "auth": store.getState().auth,
    "statistics": store.getState().statistics
  });
}), 1000);

sagaMiddleware.run(saga);

const UserProtectedRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route {...rest} render={props => Storage.Fetch('token') ? <Component {...props} /> : <Redirect to={{ pathname: "/" }} />} />
  )
}

const App = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Provider store={store}>
      <LoaderContext.Provider value={{ loading, setLoading }}>
        <Router>
          <React.Fragment>
            <Switch>
              <Route path="/" component={InstallAppCallBack} exact />
              <Route path="/install-app" component={InstallApp} exact />
              <UserProtectedRoute path="/dashboard" component={Dashboard} exact />
              <UserProtectedRoute path="/products" component={Products} exact />
              <UserProtectedRoute path="/rules" component={Rules} exact />
              <UserProtectedRoute path="/billing" component={Billing} exact />
              <UserProtectedRoute path="/abandoned-cart" component={AbandonedCart} exact />
              <UserProtectedRoute path="/rule-settings" component={RuleSettings} exact />
              <UserProtectedRoute path="/cart-settings" component={CartSettings} exact />
              <UserProtectedRoute path="/email-settings" component={EmailSettings} exact />
              <UserProtectedRoute path="/rules/:id" component={RuleDetails} exact />
              <UserProtectedRoute path="/upgrade-plan" component={UpgradePlan} exact />
              <UserProtectedRoute path="/cancel-plan" component={CancelPlan} exact />
            </Switch>
          </React.Fragment>
        </Router>
      </LoaderContext.Provider>
    </Provider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();