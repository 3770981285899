import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

import Spinner from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function UpgradePlan() {
    const dispatch = useDispatch();
    const chargeProps = useSelector(state => state.charge);

    let location = new URLSearchParams(useLocation().search);
    let type = location.get("type");

    useEffect(() => {
        dispatch({
            type: "CREATE_RECURRING_CHARGE",
            payload: {
                page: "dashboard",
                type: type
            }
        });
    }, []);

    useEffect(() => {
        if (!isEmpty(chargeProps)) {
            window.location.href = chargeProps.recurring_application_charge.confirmation_url;
        }
    }, [chargeProps]);

    return (
        <div className="loaderContainer">
            <Spinner type="Rings" color="Black" height={150} width={150} visible={true} />
        </div>
    );
}

export default UpgradePlan;