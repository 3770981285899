import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import DashboardLayout from "../layouts/dashboard-layout";

export default function RuleSettings(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const authProps = useSelector(state => state.auth);

    const [show1, setShow1] = useState('is-hidden');
    const [show2, setShow2] = useState('is-hidden');
    const [show3, setShow3] = useState('is-hidden');

    const schema = yup.object().shape({
        add_to_cart: yup.object().shape({
            add_to_order_button: yup.string().required(),
            cancel_button: yup.string().required(),
            confirm_button: yup.string().required(),
            success_message: yup.string().required(),
            style: yup.string()
        }),
        before_checkout: yup.object().shape({
            add_to_order_button: yup.string().required(),
            cancel_button: yup.string().required(),
            confirm_button: yup.string().required(),
            success_message: yup.string().required(),
            style: yup.string()
        }),
        shopify_post_purchase: yup.object().shape({
            add_to_order_button: yup.string().required(),
            cancel_button: yup.string().required(),
            confirm_button: yup.string().required(),
            success_message: yup.string().required(),
            style: yup.string()
        })
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (form) => {
        form.abandoned_cart = authProps?.modal_data?.abandoned_cart;

        dispatch({
            type: "UPDATE_RULE_SETTINGS",
            payload: form
        });

        reset();
    }

    return (
        <DashboardLayout {...props}>
            <section>
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Rule Settings </h1>
                        <p className="is-size-5m6 mb-4">Manage the popup title and design patterns for your upsell, cross sell and post purchase modals.</p>
                    </div>
                    <div>
                        <button className="button is-primary mr-2" onClick={() => history.push('/rules')}>
                            <span className="icon is-small">
                                <i className="fa fa-chevron-left" aria-hidden="true"></i>
                            </span>
                            <span>Back</span>
                        </button>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline mt-2 mb-0">
                <div className="column is-12 pb-0">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="box">
                            <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                                <h2 className="is-size-5 has-text-weight-semibold has-text-primary">Settings - Add To Cart</h2>
                                <div>
                                    <input id="advanced-settings-1" type="checkbox" value="true" onChange={(e) => {
                                        if (e.target.checked) {
                                            setShow1('')
                                        } else {
                                            setShow1('is-hidden')
                                        }
                                    }} />
                                    <label htmlFor="advanced-settings-1" className="ml-1 has-text-weight-semibold">Show Advanced Settings</label>
                                </div>
                            </div>
                            <div className="columns is-multiline">
                                <div className="field column is-6 py-0 pt-5">
                                    <label className="label">Add to order button text</label>
                                    <input type="text" className="input" ref={register} name="add_to_cart.add_to_order_button" defaultValue={authProps?.modal_data?.add_to_cart?.add_to_order_button} />
                                    <p className="help is-danger">{errors?.add_to_cart?.add_to_order_button && "Button text is required"}</p>
                                </div>
                                <div className="field column is-6 py-0 pt-5">
                                    <label className="label">Cancel button text</label>
                                    <input type="text" className="input" ref={register} name="add_to_cart.cancel_button" defaultValue={authProps?.modal_data?.add_to_cart?.cancel_button} />
                                    <p className="help is-danger">{errors?.add_to_cart?.cancel_button && "Button text is required"}</p>
                                </div>
                                <div className="field column is-6 py-0">
                                    <label className="label">Confirm button text</label>
                                    <input type="text" className="input" ref={register} name="add_to_cart.confirm_button" defaultValue={authProps?.modal_data?.add_to_cart?.confirm_button} />
                                    <p className="help is-danger">{errors?.add_to_cart?.confirm_button && "Button text is required"}</p>
                                </div>
                                <div className="field column is-6 py-0">
                                    <label className="label">Success message</label>
                                    <input type="text" className="input" ref={register} name="add_to_cart.success_message" defaultValue={authProps?.modal_data?.add_to_cart?.success_message} />
                                    <p className="help is-danger">{errors?.add_to_cart?.success_message && "Success Message is required"}</p>
                                </div>
                                <div className={`field column is-12 pt-0 ${show1}`}>
                                    <label className="label">Custom Styling</label>
                                    <textarea type="text" className="textarea" rows={8} ref={register} name="add_to_cart.style" defaultValue={authProps?.modal_data?.add_to_cart?.style}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                                <h2 className="is-size-5 has-text-weight-semibold has-text-primary">Settings - Before Checkout</h2>
                                <div>
                                    <input id="advanced-settings-2" type="checkbox" value="true" onChange={(e) => {
                                        if (e.target.checked) {
                                            setShow2('')
                                        } else {
                                            setShow2('is-hidden')
                                        }
                                    }} />
                                    <label htmlFor="advanced-settings-2" className="ml-1 has-text-weight-semibold">Show Advanced Settings</label>
                                </div>
                            </div>
                            <div className="columns is-multiline">
                                <div className="field column is-6 py-0 pt-5">
                                    <label className="label">Add to order button text</label>
                                    <input type="text" className="input" ref={register} name="before_checkout.add_to_order_button" defaultValue={authProps?.modal_data?.before_checkout?.add_to_order_button} />
                                    <p className="help is-danger">{errors?.before_checkout?.add_to_order_button && "Button text is required"}</p>
                                </div>
                                <div className="field column is-6 py-0 pt-5">
                                    <label className="label">Cancel button text</label>
                                    <input type="text" className="input" ref={register} name="before_checkout.cancel_button" defaultValue={authProps?.modal_data?.before_checkout?.cancel_button} />
                                    <p className="help is-danger">{errors?.before_checkout?.cancel_button && "Button text is required"}</p>
                                </div>
                                <div className="field column is-6 py-0">
                                    <label className="label">Confirm button text</label>
                                    <input type="text" className="input" ref={register} name="before_checkout.confirm_button" defaultValue={authProps?.modal_data?.before_checkout?.confirm_button} />
                                    <p className="help is-danger">{errors?.before_checkout?.confirm_button && "Button text is required"}</p>
                                </div>
                                <div className="field column is-6 py-0">
                                    <label className="label">Success message</label>
                                    <input type="text" className="input" ref={register} name="before_checkout.success_message" defaultValue={authProps?.modal_data?.before_checkout?.success_message} />
                                    <p className="help is-danger">{errors?.before_checkout?.success_message && "Success Message is required"}</p>
                                </div>
                                <div className={`field column is-12 pt-0 ${show2}`}>
                                    <label className="label">Custom Styling</label>
                                    <textarea type="text" className="textarea" rows={8} ref={register} name="before_checkout.style" defaultValue={authProps?.modal_data?.before_checkout?.style}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                                <h2 className="is-size-5 has-text-weight-semibold has-text-primary">Settings - Post Purchase</h2>
                                <div>
                                    <input id="advanced-settings-3" type="checkbox" value="true" onChange={(e) => {
                                        if (e.target.checked) {
                                            setShow3('')
                                        } else {
                                            setShow3('is-hidden')
                                        }
                                    }} />
                                    <label htmlFor="advanced-settings-3" className="ml-1 has-text-weight-semibold">Show Advanced Settings</label>
                                </div>
                            </div>
                            <div className="columns is-multiline">
                                <div className="field column is-6 py-0 pt-5">
                                    <label className="label">Add to order button text</label>
                                    <input type="text" className="input" ref={register} name="shopify_post_purchase.add_to_order_button" defaultValue={authProps?.modal_data?.shopify_post_purchase?.add_to_order_button} />
                                    <p className="help is-danger">{errors?.shopify_post_purchase?.add_to_order_button && "Button text is required"}</p>
                                </div>
                                <div className="field column is-6 py-0 pt-5">
                                    <label className="label">Cancel button text</label>
                                    <input type="text" className="input" ref={register} name="shopify_post_purchase.cancel_button" defaultValue={authProps?.modal_data?.shopify_post_purchase?.cancel_button} />
                                    <p className="help is-danger">{errors?.shopify_post_purchase?.cancel_button && "Button text is required"}</p>
                                </div>
                                <div className="field column is-6 py-0">
                                    <label className="label">Confirm button text</label>
                                    <input type="text" className="input" ref={register} name="shopify_post_purchase.confirm_button" defaultValue={authProps?.modal_data?.shopify_post_purchase?.confirm_button} />
                                    <p className="help is-danger">{errors?.shopify_post_purchase?.confirm_button && "Button text is required"}</p>
                                </div>
                                <div className="field column is-6 py-0">
                                    <label className="label">Success message</label>
                                    <input type="text" className="input" ref={register} name="shopify_post_purchase.success_message" defaultValue={authProps?.modal_data?.shopify_post_purchase?.success_message} />
                                    <p className="help is-danger">{errors?.shopify_post_purchase?.success_message && "Success Message is required"}</p>
                                </div>
                                <div className={`field column is-12 pt-0 ${show3}`}>
                                    <label className="label">Custom Styling</label>
                                    <textarea type="text" className="textarea" rows={8} ref={register} name="shopify_post_purchase.style" defaultValue={authProps?.modal_data?.shopify_post_purchase?.style}></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="is-flex is-justify-content-flex-end mt-5">
                            <button className="button is-primary">Save Settings</button>
                        </div>
                    </form>
                </div>
            </div>
        </DashboardLayout>
    )
}