import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Storage } from "../../helpers";

function InstallApp() {
    let location = new URLSearchParams(useLocation().search);
        
    useEffect(() => {
        let shop = location.get('shop');
        let permissions = process.env.REACT_APP_SHOPIFY_APP_PERMISSIONS;

        let client_id = process.env.REACT_APP_SHOPIFY_API_KEY;
        let redirect_uri = process.env.REACT_APP_APP_ENDPOINT;

        let url = `https://${shop}/admin/oauth/authorize?client_id=${client_id}&scope=${permissions}&redirect_uri=${redirect_uri}&state=${shop}&grant_options[]=offline`;
        window.location = url;
    }, []);

    return null;
}

export default InstallApp;