import { all, fork } from 'redux-saga/effects';

import * as authSagas from "./sagas/auth";
import * as shopSagas from "./sagas/shop";
import * as storeSagas from "./sagas/store";
import * as ruleSagas from "./sagas/rule";

export default function* rootSaga() {
  yield all([
    ...Object.values(authSagas),
    ...Object.values(shopSagas),
    ...Object.values(storeSagas),
    ...Object.values(ruleSagas),
  ].map(fork));
}