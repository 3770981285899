import { takeLatest, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { Storage } from '../helpers';

//    ______                __          ____                            _                ________                        
//   / ____/_______  ____ _/ /____     / __ \___  _______  ____________(_)___  ____ _   / ____/ /_  ____ __________ ____ 
//  / /   / ___/ _ \/ __ `/ __/ _ \   / /_/ / _ \/ ___/ / / / ___/ ___/ / __ \/ __ `/  / /   / __ \/ __ `/ ___/ __ `/ _ \
// / /___/ /  /  __/ /_/ / /_/  __/  / _, _/  __/ /__/ /_/ / /  / /  / / / / / /_/ /  / /___/ / / / /_/ / /  / /_/ /  __/
// \____/_/   \___/\__,_/\__/\___/  /_/ |_|\___/\___/\__,_/_/  /_/  /_/_/ /_/\__, /   \____/_/ /_/\__,_/_/   \__, /\___/ 
//                                                                          /____/                          /____/       

export function* watchCreateRecurringCharge() {
    yield takeLatest("CREATE_RECURRING_CHARGE", trackCreateRecurringCharge);
}

function* trackCreateRecurringCharge(sagaData) {
    try {
        var response = yield call(callCreateRecurringCharge, sagaData.payload);

        if (response) {
            yield put({
                type: "CREATE_RECURRING_CHARGE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "CREATE_RECURRING_CHARGE_ERROR",
            response: err
        });
    }
}

function callCreateRecurringCharge(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/create-recurring-charge", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    _____      __     ____                            _                ________                        
//   / ___/___  / /_   / __ \___  _______  ____________(_)___  ____ _   / ____/ /_  ____ __________ ____ 
//   \__ \/ _ \/ __/  / /_/ / _ \/ ___/ / / / ___/ ___/ / __ \/ __ `/  / /   / __ \/ __ `/ ___/ __ `/ _ \
//  ___/ /  __/ /_   / _, _/  __/ /__/ /_/ / /  / /  / / / / / /_/ /  / /___/ / / / /_/ / /  / /_/ /  __/
// /____/\___/\__/  /_/ |_|\___/\___/\__,_/_/  /_/  /_/_/ /_/\__, /   \____/_/ /_/\__,_/_/   \__, /\___/ 
//                                                          /____/                          /____/       

export function* watchSetRecurringCharge() {
    yield takeLatest("SET_RECURRING_CHARGE", trackSetRecurringCharge);
}

function* trackSetRecurringCharge(sagaData) {
    try {
        var response = yield call(callSetRecurringCharge, sagaData.payload);

        if (response) {
            yield put({
                type: "SET_RECURRING_CHARGE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "SET_RECURRING_CHARGE_ERROR",
            response: err
        });
    }
}

function callSetRecurringCharge(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/set-recurring-charge", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ______                      __   ____                            _                ________                        
//   / ____/___ _____  ________  / /  / __ \___  _______  ____________(_)___  ____ _   / ____/ /_  ____ __________ ____ 
//  / /   / __ `/ __ \/ ___/ _ \/ /  / /_/ / _ \/ ___/ / / / ___/ ___/ / __ \/ __ `/  / /   / __ \/ __ `/ ___/ __ `/ _ \
// / /___/ /_/ / / / / /__/  __/ /  / _, _/  __/ /__/ /_/ / /  / /  / / / / / /_/ /  / /___/ / / / /_/ / /  / /_/ /  __/
// \____/\__,_/_/ /_/\___/\___/_/  /_/ |_|\___/\___/\__,_/_/  /_/  /_/_/ /_/\__, /   \____/_/ /_/\__,_/_/   \__, /\___/ 
//                                                                         /____/                          /____/       

export function* watchCancelRecurringCharge() {
    yield takeLatest("CANCEL_RECURRING_CHARGE", trackCancelRecurringCharge);
}

function* trackCancelRecurringCharge(sagaData) {
    try {
        var response = yield call(callCancelRecurringCharge, sagaData.payload);

        if (response) {
            yield put({
                type: "CANCEL_RECURRING_CHARGE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "CANCEL_RECURRING_CHARGE_ERROR",
            response: err
        });
    }
}

function callCancelRecurringCharge(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/cancel-recurring-charge", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    _____                      __       ____                 __           __      
//   / ___/___  ____ ___________/ /_     / __ \_________  ____/ /_  _______/ /______
//   \__ \/ _ \/ __ `/ ___/ ___/ __ \   / /_/ / ___/ __ \/ __  / / / / ___/ __/ ___/
//  ___/ /  __/ /_/ / /  / /__/ / / /  / ____/ /  / /_/ / /_/ / /_/ / /__/ /_(__  ) 
// /____/\___/\__,_/_/   \___/_/ /_/  /_/   /_/   \____/\__,_/\__,_/\___/\__/____/  

export function* watchSearchProducts() {
    yield takeLatest("SEARCH_PRODUCTS", trackSearchProducts);
}

function* trackSearchProducts(sagaData) {
    try {
        var response = yield call(callSearchProducts, sagaData.payload);

        if (response) {
            yield put({
                type: "SEARCH_PRODUCTS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "SEARCH_PRODUCTS_ERROR",
            response: err
        });
    }
}

function callSearchProducts(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/shop/search-products", {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     __    _      __     ______      ____          __  _                 
//    / /   (_)____/ /_   / ____/___  / / /__  _____/ /_(_)___  ____  _____
//   / /   / / ___/ __/  / /   / __ \/ / / _ \/ ___/ __/ / __ \/ __ \/ ___/
//  / /___/ (__  ) /_   / /___/ /_/ / / /  __/ /__/ /_/ / /_/ / / / (__  ) 
// /_____/_/____/\__/   \____/\____/_/_/\___/\___/\__/_/\____/_/ /_/____/  
                                                                        
export function* watchListCollections() {
    yield takeLatest("LIST_COLLECTIONS", trackListCollections);
}

function* trackListCollections(sagaData) {
    try {
        var response = yield call(callListCollections, sagaData.payload);

        if (response) {
            yield put({
                type: "LIST_COLLECTIONS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "LIST_COLLECTIONS_ERROR",
            response: err
        });
    }
}

function callListCollections(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/shop/list-collections", {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ___       __    __   ____        __        __           ______      ____          __  _                 
//    /   | ____/ /___/ /  / __ \__  __/ /__     / /_____     / ____/___  / / /__  _____/ /_(_)___  ____  _____
//   / /| |/ __  / __  /  / /_/ / / / / / _ \   / __/ __ \   / /   / __ \/ / / _ \/ ___/ __/ / __ \/ __ \/ ___/
//  / ___ / /_/ / /_/ /  / _, _/ /_/ / /  __/  / /_/ /_/ /  / /___/ /_/ / / /  __/ /__/ /_/ / /_/ / / / (__  ) 
// /_/  |_\__,_/\__,_/  /_/ |_|\__,_/_/\___/   \__/\____/   \____/\____/_/_/\___/\___/\__/_/\____/_/ /_/____/  
                                                                                                            
export function* watchAddRuleToCollections() {
    yield takeLatest("ADD_RULE_TO_COLLECTIONS", trackAddRuleToCollections);
}

function* trackAddRuleToCollections(sagaData) {
    try {
        var response = yield call(callAddRuleToCollections, sagaData.payload);

        if (response) {
            yield put({
                type: "ADD_RULE_TO_COLLECTIONS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "ADD_RULE_TO_COLLECTIONS_ERROR",
            response: err
        });
    }
}

function callAddRuleToCollections(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/add-rule-to-collections", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}