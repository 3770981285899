import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { find, isEmpty, capitalize, concat, compact } from "lodash";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { GetID } from "../helpers";
import { LoaderContext } from "../loader";
import DashboardLayout from "../layouts/dashboard-layout";

export default function RuleDetails(props) {
    let pageType = props.match.params.id === "create" ? "create" : "update";

    const dispatch = useDispatch();
    const history = useHistory();
    const { setLoading } = useContext(LoaderContext);
    const searchProps = useSelector(state => state.search);
    const ruleProps = useSelector(state => state.rule);

    const [name, setName] = useState('');
    const [searchProducts, setSearchProducts] = useState({});
    const [currentPosition, setCurrentPosition] = useState(0);
    const [isLevel1, showLevel1] = useState(false);
    const [isLevel2, showLevel2] = useState(false);
    const [isLevel3, showLevel3] = useState(false);
    const [modal, setModal] = useState('');

    const [level0data, setLevel0data] = useState({});
    const [level1data, setLevel1data] = useState({});
    const [level2data, setLevel2data] = useState({});
    const [level3data, setLevel3data] = useState({});
    const [level4data, setLevel4data] = useState({});
    const [level5data, setLevel5data] = useState({});
    const [level6data, setLevel6data] = useState({});

    useEffect(() => {
        setLoading(true);

        dispatch({
            type: "SEARCH_PRODUCTS",
            payload: {}
        });

        if (pageType === "update") {
            dispatch({
                type: "FETCH_RULE",
                payload: {
                    id: props.match.params.id
                }
            });
        }
    }, []);

    useEffect(() => {
        if (!isEmpty(searchProps)) {
            setSearchProducts(searchProps);
        }
    }, [searchProps]);

    useEffect(() => {
        setLoading(false);

        if (!isEmpty(ruleProps) && pageType === "update") {
            setName(ruleProps?.name);
            document.getElementsByName('type')[0].value = ruleProps?.type;
            document.getElementsByName('trigger')[0].value = ruleProps?.trigger;

            var offers = ruleProps?.offers?.map((offer) => {
                manageLevelData('push', offer.position, offer);

                if (offer.position === 1) {
                    showLevel1(true);
                }
                if (offer.position === 3) {
                    showLevel2(true);
                }
                if (offer.position === 5) {
                    showLevel3(true);
                }

                return offer;
            });
        }

        connectionManager('init');
    }, [ruleProps]);

    const { register, handleSubmit, errors, getValues } = useForm({
        resolver: yupResolver(
            yup.object().shape({
                name: yup.string().required(),
                trigger: yup.string().required(),
                type: yup.string().required(),
                offers: yup.array().of(
                    yup.object().shape({
                        position: yup.number().required(),
                        title: yup.string().required(),
                        description: yup.string().required(),
                        products: yup.array().of(
                            yup.object().shape({
                                product_id: yup.string(),
                                title: yup.string(),
                                image: yup.string(),
                                variants_count: yup.number()
                            })
                        ).required()
                    })
                )
            })
        )
    });

    const CreateRule = (form) => {
        setLoading(true);

        dispatch({
            type: "CREATE_RULE",
            payload: form
        });

        setTimeout(() => {
            setLoading(false);
            history.push('/rules');
            window.location.reload();
        }, 2000);
    }

    const UpdateRule = (form) => {
        setLoading(true);

        dispatch({
            type: "UPDATE_RULE",
            payload: form
        });

        setTimeout(() => {
            setLoading(false);
            history.push('/rules');
            window.location.reload();
        }, 2000);
    }

    const AddProducts = (position) => {
        let values = getValues();
        manageLevelData('push', position, values.offers[position]);
        setModal('is-active');
        setCurrentPosition(position);
    }

    const RemoveProduct = (position, index) => {
        let currentOffer = manageLevelData('get', position);
        let currentProducts = currentOffer.products;
        currentProducts.splice(index, 1);
        currentOffer.products = currentProducts;
        manageLevelData('push', position, currentOffer);
        connectionManager('update');
    }

    const SearchProducts = (e) => {
        let searchString = e.target.value.trim();

        if (searchString.length >= 3) {
            dispatch({
                type: "SEARCH_PRODUCTS",
                payload: {
                    search: searchString
                }
            });
        }
    }

    const SelectProducts = () => {
        setLoading(true);
        setModal('');

        let products = document.getElementsByClassName('product_id');
        let selectedProducts = [];
        let mergedProducts = [];

        for (let i = 0; i < products.length; i++) {
            if (products[i].checked == true) {
                products[i].checked = false;
                let matchedProduct = find(searchProducts, function (o) {
                    return o.node.id == products[i].value;
                });

                let data = {
                    product_id: matchedProduct.node.id,
                    title: matchedProduct.node.title,
                    image: matchedProduct.node.featuredImage.url,
                    variants_count: matchedProduct.node.totalVariants
                }

                selectedProducts.push(data);
            }
        }

        let currentOffer = manageLevelData('get', currentPosition);

        if (currentOffer.products === undefined) {
            mergedProducts = selectedProducts;
        } else {
            mergedProducts = concat(currentOffer.products, selectedProducts);
        }

        currentOffer.products = mergedProducts;
        manageLevelData('push', currentPosition, currentOffer);

        setTimeout(() => {
            setLoading(false);
            connectionManager('update');
        }, 1000);
    }

    const connectionManager = (type) => {
        if (type === "update") {
            var connections = document.querySelectorAll('.connection');

            for (let i = 0; i < connections.length; i++) {
                connections[i].parentNode.removeChild(connections[i]);
            }
        }

        setTimeout(() => {
            window.jQuery('.offer-position-0 .anchor-start').connections({ to: '.offer-position-1 .anchor-end' });
            window.jQuery('.offer-position-0 .anchor-start').connections({ to: '.offer-position-2 .anchor-end' });
            window.jQuery('.offer-position-1 .anchor-start').connections({ to: '.offer-position-3 .anchor-end' });
            window.jQuery('.offer-position-1 .anchor-start').connections({ to: '.offer-position-4 .anchor-end' });
            window.jQuery('.offer-position-2 .anchor-start').connections({ to: '.offer-position-5 .anchor-end' });
            window.jQuery('.offer-position-2 .anchor-start').connections({ to: '.offer-position-6 .anchor-end' });
        }, 100);
    }

    const manageLevels = (level, value) => {
        let template = {
            title: "",
            description: "",
            products: [],
            position: level
        }

        if (level === 1) {
            if (value) {
                manageLevelData('push', 1, template);
                manageLevelData('push', 2, template);
            } else {
                manageLevelData('push', 1, []);
                manageLevelData('push', 2, []);
            }

            showLevel1(value);
        } else if (level === 2) {
            if (value) {
                manageLevelData('push', 3, template);
                manageLevelData('push', 4, template);
            } else {
                manageLevelData('push', 3, []);
                manageLevelData('push', 4, []);
            }

            showLevel2(value);
        } else if (level === 3) {
            if (value) {
                manageLevelData('push', 5, template);
                manageLevelData('push', 6, template);
            } else {
                manageLevelData('push', 5, []);
                manageLevelData('push', 6, []);
            }

            showLevel3(value);
        }

        connectionManager('update');
    }

    const manageVisibility = (level, value) => {
        if (value) {
            document.getElementsByClassName(`level-${level}`)[0].classList.add("opacity-overlay");
        } else {
            document.getElementsByClassName(`level-${level}`)[0].classList.remove("opacity-overlay");
        }
    }

    const manageLevelData = (type, position, data) => {
        if (type === "get") {
            if (position === 0) {
                return level0data;
            } else if (position === 1) {
                return level1data;
            } else if (position === 2) {
                return level2data;
            } else if (position === 3) {
                return level3data;
            } else if (position === 4) {
                return level4data;
            } else if (position === 5) {
                return level5data;
            } else if (position === 6) {
                return level6data;
            }
        } else if (type === 'push') {
            let newData = Object.assign({}, data);
            if (position === 0) {
                setLevel0data(newData);
            } else if (position === 1) {
                setLevel1data(newData);
            } else if (position === 2) {
                setLevel2data(newData);
            } else if (position === 3) {
                setLevel3data(newData);
            } else if (position === 4) {
                setLevel4data(newData);
            } else if (position === 5) {
                setLevel5data(newData);
            } else if (position === 6) {
                setLevel6data(newData);
            }
        }
    }

    const OfferBlock = (props) => {
        var products = [];

        if (props.offers.products !== undefined) {
            products = props.offers.products;
        }

        var backgroundColor = '';
        if (props.position === 1 || props.position === 2) {
            backgroundColor = "#fefef3";
        } else if (props.position === 3 || props.position === 4) {
            backgroundColor = "#f4fffe";
        } else if (props.position === 5 || props.position === 6) {
            backgroundColor = "#f5fbed";
        }

        return (
            <React.Fragment>
                <div className={`box offer-anchor offer-position-${props.position}`} style={{ "backgroundColor": backgroundColor }}>
                    <div className="is-flex is-align-items-center is-justify-content-space-between">
                        <div>
                            <h1 className="is-size-5 has-text-weight-semibold">{props.heading}</h1>
                            <p>{props.subheading}</p>
                        </div>
                        <div>
                            <a className="button is-primary" onClick={() => AddProducts(props.position)}> Add Products </a>
                        </div>
                    </div>
                    <div className="mt-2">
                        <div className="field">
                            <label className="label">Title</label>
                            <input type="hidden" className="input" name={`offers[${props.position}].position`} defaultValue={props?.position} ref={register} />
                            <input type="text" className="input" name={`offers[${props.position}].title`} defaultValue={props.offers?.title} ref={register} />
                        </div>
                        <div className="field">
                            <label className="label">Description</label>
                            <input type="text" className="input" name={`offers[${props.position}].description`} defaultValue={props.offers?.description} ref={register} />
                        </div>
                        <div className="table-container">
                            <table className="table is-narrow is-bordered is-fullwidth">
                                <thead>
                                    <tr>
                                        <th className="has-text-centered">Image</th>
                                        <th className="has-text-centered">Title</th>
                                        <th className="has-text-centered">Variants Count</th>
                                        <th className="has-text-centered">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(products) && products.map((product, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="has-text-centered">
                                                        <input type="hidden" name={`offers[${props.position}].products[${index}].image`} value={product?.image} ref={register} />
                                                        <img className="image is-64x64 mx-auto" src={product?.image} />
                                                    </td>
                                                    <td className="has-text-centered">
                                                        <input type="hidden" name={`offers[${props.position}].products[${index}].product_id`} value={product?.product_id} ref={register} />
                                                        <input type="hidden" name={`offers[${props.position}].products[${index}].title`} value={product?.title} ref={register} />
                                                        {product?.title}
                                                    </td>
                                                    <td className="has-text-centered">
                                                        <input type="hidden" name={`offers[${props.position}].products[${index}].variants_count`} value={product?.variants_count} ref={register} />
                                                        {product?.variants_count}
                                                    </td>
                                                    <td className="has-text-centered">
                                                        <a className="button is-danger is-small" onClick={() => RemoveProduct(props.position, index)}>Remove Product</a>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        isEmpty(products) ? <tr>
                                            <td className="has-text-centered is-size-4 py-5" colSpan={6}> No Products Found </td>
                                        </tr> : ""
                                    }
                                </tbody>
                            </table>
                            <p className="help is-danger">{errors?.offers && errors?.offers[props.position]?.products && 'Please add some products for the offer'}</p>
                        </div>
                    </div>
                    <span className="anchor-start"></span>
                    <span className="anchor-end"></span>
                </div>
                <div className="is-flex is-justify-content-center pb-5">
                    {
                        props.position === 0 && isLevel1 === false ? <button className="button is-success" onClick={() => manageLevels(1, true)}>
                            <span className="icon is-small">
                                <i className="fa fa-plus"></i>
                            </span>
                            <span> Add New Offer </span>
                        </button> : ""
                    }
                    {
                        props.position === 0 && isLevel1 === true ? <button className="button is-danger" onClick={() => manageLevels(1, false)} onMouseEnter={() => manageVisibility(1, true)} onMouseLeave={() => manageVisibility(1, false)}>
                            <span className="icon is-small">
                                <i className="fa fa-times"></i>
                            </span>
                            <span> Remove Offer </span>
                        </button> : ""
                    }
                    {
                        props.position === 1 && isLevel2 === false ? <button className="button is-success" onClick={() => manageLevels(2, true)}>
                            <span className="icon is-small">
                                <i className="fa fa-plus"></i>
                            </span>
                            <span> Add New Offer </span>
                        </button> : ""
                    }
                    {
                        props.position === 1 && isLevel2 === true ? <button className="button is-danger" onClick={() => manageLevels(2, false)} onMouseEnter={() => manageVisibility(2, true)} onMouseLeave={() => manageVisibility(2, false)}>
                            <span className="icon is-small">
                                <i className="fa fa-times"></i>
                            </span>
                            <span> Remove Offer </span>
                        </button> : ""
                    }
                    {
                        props.position === 2 && isLevel3 === false ? <button className="button is-success" onClick={() => manageLevels(3, true)}>
                            <span className="icon is-small">
                                <i className="fa fa-plus"></i>
                            </span>
                            <span> Add New Offer </span>
                        </button> : ""
                    }
                    {
                        props.position === 2 && isLevel3 === true ? <button className="button is-danger" onClick={() => manageLevels(3, false)} onMouseEnter={() => manageVisibility(3, true)} onMouseLeave={() => manageVisibility(3, false)}>
                            <span className="icon is-small">
                                <i className="fa fa-times"></i>
                            </span>
                            <span> Remove Offer </span>
                        </button> : ""
                    }
                </div>
            </React.Fragment>
        )
    }

    return (
        <DashboardLayout {...props}>
            <section>
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> {capitalize(pageType)} Rule </h1>
                    </div>
                    <div>
                        <button className="button is-primary with-shadow" onClick={() => history.push('/rules')}> Back </button>
                    </div>
                </div>
            </section>

            <form>
                <div className="columns is-multiline mt-2 mb-0">
                    <div className="column is-12 pb-0">
                        <div className="box">
                            {
                                pageType === "update" ?
                                    <input type="hidden" name="id" value={props.match.params.id} ref={register} /> :
                                    ""
                            }
                            <div className="field">
                                <label className="label">Name</label>
                                <input type="text" className="input" name="name" defaultValue={name} placeholder="Enter name" ref={register} />
                                <p className="help is-danger">{errors.name && 'Please add a name for the rule'}</p>
                            </div>
                            <div className="columns">
                                <div className="field column is-6">
                                    <label className="label">Trigger</label>
                                    <div className="select is-fullwidth">
                                        <select name="trigger" ref={register}>
                                            <option value=''>Select Trigger</option>
                                            <option value='add-to-cart'>Add to cart</option>
                                            <option value='before-checkout'>Before checkout</option>
                                            <option value='shopify-post-purchase'>Post purchase</option>
                                        </select>
                                    </div>
                                    <p className="help is-danger">{errors.type && 'Please select a trigger event'}</p>
                                </div>
                                <div className="field column is-6">
                                    <label className="label">Type</label>
                                    <div className="select is-fullwidth">
                                        <select name="type" ref={register}>
                                            <option value=''>Select Type</option>
                                            <option value='upsell'>Upsell</option>
                                            <option value='cross-sell'>Cross Sell</option>
                                        </select>
                                    </div>
                                    <p className="help is-danger">{errors.type && 'Please select a type'}</p>
                                </div>
                            </div>
                        </div>

                        <div className="offer-block mb-5">
                            <OfferBlock position={0} heading="Create Offer for the Rule" subheading="Now you get to decide what you want to offer to your customers and how you want it to work" offers={level0data} />
                            {
                                isLevel1 ? <div className="offer-margin-left level-1">
                                    <OfferBlock position={1} heading="Accept Offer" offers={level1data} />
                                    {
                                        isLevel2 ? <div className="mb-5 offer-margin-left level-2">
                                            <OfferBlock position={3} heading="Accept Offer" offers={level3data} />
                                            <OfferBlock position={4} heading="Decline Offer" offers={level4data} />
                                        </div> : ""
                                    }
                                    <OfferBlock position={2} heading="Decline Offer" offers={level2data} />
                                    {
                                        isLevel3 ? <div className="mb-5 offer-margin-left level-3">
                                            <OfferBlock position={5} heading="Accept Offer" offers={level5data} />
                                            <OfferBlock position={6} heading="Decline Offer" offers={level6data} />
                                        </div> : ""
                                    }
                                </div> : ""
                            }
                        </div>

                        <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                            <div>
                                <a className="button is-primary is-light" onClick={() => history.push('/rules')}>Cancel</a>
                            </div>
                            <div>
                                {
                                    pageType === "create" ?
                                        <button className="button is-primary" onClick={handleSubmit(CreateRule)} onClickCapture={() => connectionManager('update')}>Create Rule</button> :
                                        <button className="button is-primary" onClick={handleSubmit(UpdateRule)} onClickCapture={() => connectionManager('update')}>Update Rule</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div className={`modal ${modal}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title is-size-5 has-text-weight-semibold">Add Product</p>
                        <a className="delete" aria-label="close" onClick={() => setModal('')}>x</a>
                    </header>
                    <section className="modal-card-body py-0">
                        <div className="field">
                            <label className="is-size-5m6 has-text-weight-semibold">Search Product</label>
                            <input type="search" className="input" name="search" onChange={(e) => SearchProducts(e)} placeholder="Search for your products on your store" />
                        </div>
                        <div className="table-container" style={{ "overflowY": "scroll", "height": "400px" }}>
                            <table className="table w-full">
                                <tbody>
                                    {
                                        !isEmpty(searchProducts) && Object.values(searchProducts).map((product, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><input type="checkbox" className="product_id" name={`product_id_${GetID(product?.node?.id)}`} value={product?.node?.id} /></td>
                                                    <td><img className="image is-48x48" src={product?.node?.featuredImage?.url} /></td>
                                                    <td>{product?.node?.title}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <footer className="modal-card-foot is-justify-content-flex-end">
                        <a className="button has-background-white-bis border border-sold border-color-white-bis has-text-dark" onClick={() => setModal('')}>Cancel</a>
                        <button className="button is-primary" type="submit" onClick={() => SelectProducts()}>Submit</button>
                    </footer>
                </div>
            </div>
        </DashboardLayout>
    )
}