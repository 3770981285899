import { Storage } from './helpers';
import Swal from "sweetalert2";

const rootReducer = (state = {}, action) => {
    if (action.type.includes('SUCCESS') && action.response?.message) {
        Swal.fire({
            icon: "success",
            title: action.response.message,
            timer: 3000,
            showConfirmButton: false
        });
    } else if (action.type.includes('ERROR') && action.response?.message) {
        if (action.response?.message === "getaddrinfo ENOTFOUND null" || action.response?.message === "getaddrinfo EAI_AGAIN null") {
            Swal.fire({
                icon: "error",
                title: "Please open the app through your shopify store."
            }).then((result) => {
                let currentState = JSON.parse(Storage.Fetch('state'));
                window.location.href = `https://${currentState.auth.shop}/admin/apps`;
                sessionStorage.clear();
            });
        } else if (action.response?.message === "jwt expired") {
            Swal.fire({
                icon: "error",
                title: "Your login session has expired. Please login again to continue."
            }).then((result) => {
                let currentState = JSON.parse(Storage.Fetch('state'));
                window.location.href = `https://${currentState.auth.shop}/admin/apps`;
                sessionStorage.clear();
            });
        } else {
            Swal.fire({
                icon: "error",
                title: action.response.message
            });
        }
    }

    switch (action.type) {
        //     ___         __  __  
        //    /   | __  __/ /_/ /_ 
        //   / /| |/ / / / __/ __ \
        //  / ___ / /_/ / /_/ / / /
        // /_/  |_\__,_/\__/_/ /_/ 
        case 'INIT_SUCCESS':
            return {
                init: action.response.data
            };
        case 'INIT_ERROR':
            return Object.assign({}, {
                ...state,
                init: {
                    loading: false
                }
            });
        case 'LOGIN_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'LOGIN_ERROR':
            return Object.assign({}, {
                ...state,
                auth: {
                    loading: false
                }
            });
        case 'DASHBOARD_STATISTICS_SUCCESS':
            return Object.assign({}, {
                ...state,
                statistics: action.response.data
            });
        case 'DASHBOARD_STATISTICS_ERROR':
            return Object.assign({}, {
                ...state,
                statistics: action.response.data
            });
        case 'LIST_CHARGES_SUCCESS':
            return Object.assign({}, {
                ...state,
                usage_charges: action.response.data
            });
        case 'LIST_CHARGES_ERROR':
            return Object.assign({}, {
                ...state,
                usage_charges: action.response.data
            });
        //    _____ __              
        //   / ___// /_  ____  ____ 
        //   \__ \/ __ \/ __ \/ __ \
        //  ___/ / / / / /_/ / /_/ /
        // /____/_/ /_/\____/ .___/ 
        //                 /_/      
        case 'CREATE_RECURRING_CHARGE_SUCCESS':
            return Object.assign({}, {
                ...state,
                charge: action.response.data
            });
        case 'CREATE_RECURRING_CHARGE_ERROR':
            return Object.assign({}, {
                ...state,
                charge: {
                    loading: false
                }
            });
        case 'SET_RECURRING_CHARGE_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'SET_RECURRING_CHARGE_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'CANCEL_RECURRING_CHARGE_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'CANCEL_RECURRING_CHARGE_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'SEARCH_PRODUCTS_SUCCESS':
            return Object.assign({}, {
                ...state,
                search: action.response.data
            });
        case 'SEARCH_PRODUCTS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'LIST_COLLECTIONS_SUCCESS':
            return Object.assign({}, {
                ...state,
                collections: action.response.data
            });
        case 'LIST_COLLECTIONS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'ADD_RULE_TO_COLLECTIONS_SUCCESS':
            return Object.assign({}, {
                ...state
            });
        case 'ADD_RULE_TO_COLLECTIONS_ERROR':
            return Object.assign({}, {
                ...state
            });
        //    _____ __                
        //   / ___// /_____  ________ 
        //   \__ \/ __/ __ \/ ___/ _ \
        //  ___/ / /_/ /_/ / /  /  __/
        // /____/\__/\____/_/   \___/ 
        case 'FETCH_PRODUCTS_SUCCESS':
            return Object.assign({}, {
                ...state,
                products: action.response.data
            });
        case 'FETCH_PRODUCTS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'ADD_PRODUCTS_SUCCESS':
            return Object.assign({}, {
                ...state
            });
        case 'ADD_PRODUCTS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'REMOVE_PRODUCT_UCCESS':
            return Object.assign({}, {
                ...state
            });
        case 'REMOVE_PRODUCT_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'ADD_RULE_TO_PRODUCTS_SUCCESS':
            return Object.assign({}, {
                ...state
            });
        case 'ADD_RULE_TO_PRODUCTS_ERROR':
            return Object.assign({}, {
                ...state
            });
        //    __  __                ____
        //   / / / /___  ________  / / /
        //  / / / / __ \/ ___/ _ \/ / / 
        // / /_/ / /_/ (__  )  __/ / /  
        // \____/ .___/____/\___/_/_/   
        //     /_/                      
        case 'FETCH_RULES_SUCCESS':
            return Object.assign({}, {
                ...state,
                rules: action.response.data
            });
        case 'FETCH_RULES_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'FETCH_RULE_SUCCESS':
            return Object.assign({}, {
                ...state,
                rule: action.response.data
            });
        case 'FETCH_RULE_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'CREATE_RULE_SUCCESS':
            return Object.assign({}, {
                ...state
            });
        case 'CREATE_RULE_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'UPDATE_RULE_SUCCESS':
            return Object.assign({}, {
                ...state
            });
        case 'UPDATE_RULE_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'DELETE_RULE_SUCCESS':
            return Object.assign({}, {
                ...state
            });
        case 'DELETE_RULE_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'UPDATE_RULE_SETTINGS_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'UPDATE_RULE_SETTINGS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'LIST_ABANDONED_CART_SUCCESS':
            return Object.assign({}, {
                ...state,
                carts: action.response.data
            });
        case 'LIST_ABANDONED_CART_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'REMOVE_ABANDONED_CART_SUCCESS':
            return Object.assign({}, {
                ...state,
                carts: action.response.data
            });
        case 'REMOVE_ABANDONED_CART_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'UPDATE_EMAIL_SETTINGS_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'UPDATE_EMAIL_SETTINGS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'TRIGGER_TEST_EMAIL_SUCCESS':
            return Object.assign({}, {
                ...state,
            });
        case 'TRIGGER_TEST_EMAIL_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'TRIGGER_USER_EMAIL_SUCCESS':
            return Object.assign({}, {
                ...state,
            });
        case 'TRIGGER_USER_EMAIL_ERROR':
            return Object.assign({}, {
                ...state
            });
        default:
            return state;
    }
}

export default rootReducer;