import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RestrictPages } from "../helpers";

function Sidebar() {
    const history = useHistory();
    const authProps = useSelector(state => state.auth);

    return (
        <aside className="menu sidebar">
            <div className="is-flex is-flex-direction-column h-full sidebar-in">
                <div className="navbar-brand is-hidden-mobile is-hidden-tablet-only has-background-primary">
                    <div className="navbar-item brand-text mx-auto" onClick={() => history.push('/dashboard')}>
                        <span className="has-text-white is-size-4">Smart Upsell</span>
                    </div>
                </div>
                <div className="pt-5">
                    <p className="menu-label px-4 has-text-white mt-1"> General </p>
                    <ul className="menu-list">
                        <li className={`${window.location.href.includes('dashboard') ? 'active' : ''}`}>
                            <a className="is-flex" onClick={(e) => history.push('/dashboard')}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-dashboard"></i>
                                    </span>
                                    <span>Home</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                    <p className="menu-label px-4 has-text-white mt-4"> Upsell Manager </p>
                    <ul className="menu-list">
                        <li className={`${window.location.href.includes('rules') ? 'active' : ''}`}>
                            <a className="is-flex" onClick={(e) => RestrictPages(history, authProps?.plan_details, "FREE", '/rules', 'popup')}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-certificate"></i>
                                    </span>
                                    <span>Rules</span>
                                </span>
                            </a>
                        </li>
                        <li className={`${window.location.href.includes('products') ? 'active' : ''}`}>
                            <a className="is-flex" onClick={(e) => RestrictPages(history, authProps?.plan_details, "FREE", '/products', 'popup')}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-tags"></i>
                                    </span>
                                    <span>Products</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                    <p className="menu-label px-4 has-text-white mt-4"> Abandoned Cart Saver </p>
                    <ul className="menu-list">
                        <li className={`${window.location.href.includes('abandoned-cart') ? 'active' : ''}`}>
                            <a className="is-flex" onClick={(e) => RestrictPages(history, authProps?.plan_details, "ULTIMATE", '/abandoned-cart', 'popup')}>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className="fa fa-dashboard"></i>
                                    </span>
                                    <span>Abandoned Cart</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="mt-auto mb-4">
                    <ul className="menu-list">
                        <li>
                            <a className="is-flex" href="mailto:contact@smartsusbcriptions.com" target="_blank" rel="noopener noreferrer">
                                <span className="icon-text">
                                    <span className="icon"><i className="fa fa-question-circle"></i></span>
                                    <span>Help</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </aside>
    )
}

export default Sidebar;