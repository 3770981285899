import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import { isEmpty } from "lodash";
import moment from "moment";

import { RestrictPages } from "../helpers";
import DashboardLayout from "../layouts/dashboard-layout";

export default function Billing(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const authProps = useSelector(state => state.auth);
    const usageProps = useSelector(state => state.usage_charges);
    const [charges, setCharges] = useState({});

    const limit = 50;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        RestrictPages(history, authProps?.plan_details, 'FREE', '/dashboard', 'redirect');

        FetchCharges();
    }, [page]);

    useEffect(() => {
        if (!isEmpty(usageProps)) {
            setCharges(usageProps.results);
            setTotal(usageProps.total);
        } else {
            setCharges([]);
            setTotal(0);
        }
    }, [usageProps]);

    const FetchCharges = () => {
        dispatch({
            type: "LIST_CHARGES",
            payload: {
                page: page - 1,
                limit: limit
            }
        });
    }

    return (
        <DashboardLayout {...props}>
            <section>
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Billing </h1>
                        <p className="is-size-5m6">List of recent billing charges for the store</p>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline mt-2 mb-0">
                <div className="column is-6">
                    <div className="box">
                        <h2 className="is-size-5 has-text-weight-semibold mb-2">User Information</h2>
                        <p className="mb-2">{authProps?.firstname} {authProps?.lastname} - {authProps?.email} - {authProps?.country}</p>
                    </div>
                </div>
                <div className="column is-6">
                    <div className="box">
                        <h2 className="is-size-5 has-text-weight-semibold mb-2">Current Plan</h2>
                        <p className="mb-2">{authProps?.plan_details?.name}</p>
                    </div>
                </div>

                <div className="column is-12 pb-0">
                    <div className="box table-container">
                        <table className="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Description</th>
                                    <th>Value</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !isEmpty(charges) && Object.values(charges).map((charge, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{charge.description}</td>
                                                <td>{charge.price} <b>{charge.currency}</b></td>
                                                <td>{moment(charge?.created_at).format('DD MMM, YYYY')}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {
                            isEmpty(charges) ? <p className="pt-0 pb-5 has-text-centered is-fullwidth box-content">No records found</p> : ""
                        }
                    </div>
                </div>
            </div>

            <div className="is-flex is-align-items-center is-justify-content-space-between mt-4">
                <div>Showing <b>{charges.length}</b> of <b>{total}</b> records</div>
                <Pagination activePage={page} itemsCountPerPage={limit} totalItemsCount={total} pageRangeDisplayed={5} onChange={(e) => setPage(e)} />
            </div>
        </DashboardLayout>
    )
}