import { takeLatest, put, call } from "redux-saga/effects";
import axios from "axios";

import { Storage } from '../helpers';

//     ____      _ __ 
//    /  _/___  (_) /_
//    / // __ \/ / __/
//  _/ // / / / / /_  
// /___/_/ /_/_/\__/  
                   
export function* watchInit() {
    yield takeLatest("INIT", trackInit);
}

function* trackInit(sagaData) {
    try {
        var response = yield call(callInit, sagaData.payload);

        if (response) {
            yield put({
                type: "INIT_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "INIT_ERROR",
            response: err
        });
    }
}

function callInit(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/auth/init", saga).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     __                _     
//    / /   ____  ____ _(_)___ 
//   / /   / __ \/ __ `/ / __ \
//  / /___/ /_/ / /_/ / / / / /
// /_____/\____/\__, /_/_/ /_/ 
//             /____/          

export function* watchLogin() {
    yield takeLatest("LOGIN", trackLogin);
}

function* trackLogin(sagaData) {
    try {
        var response = yield call(callLogin, sagaData.payload);

        if (response) {
            yield put({
                type: "LOGIN_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "LOGIN_ERROR",
            response: err
        });
    }
}

function callLogin(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/auth/login", saga).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     ____             __    __                         __   _____ __        __  _      __  _          
//    / __ \____ ______/ /_  / /_  ____  ____ __________/ /  / ___// /_____ _/ /_(_)____/ /_(_)_________
//   / / / / __ `/ ___/ __ \/ __ \/ __ \/ __ `/ ___/ __  /   \__ \/ __/ __ `/ __/ / ___/ __/ / ___/ ___/
//  / /_/ / /_/ (__  ) / / / /_/ / /_/ / /_/ / /  / /_/ /   ___/ / /_/ /_/ / /_/ (__  ) /_/ / /__(__  ) 
// /_____/\__,_/____/_/ /_/_.___/\____/\__,_/_/   \__,_/   /____/\__/\__,_/\__/_/____/\__/_/\___/____/  
                                                                                                     
export function* watchDashboardStatistics() {
    yield takeLatest("DASHBOARD_STATISTICS", trackDashboardStatistics);
}

function* trackDashboardStatistics(sagaData) {
    try {
        var response = yield call(callDashboardStatistics, sagaData.payload);

        if (response) {
            yield put({
                type: "DASHBOARD_STATISTICS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "DASHBOARD_STATISTICS_ERROR",
            response: err
        });
    }
}

function callDashboardStatistics(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/auth/dashboard-statistics", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     __    _      __     ________                              
//    / /   (_)____/ /_   / ____/ /_  ____ __________ ____  _____
//   / /   / / ___/ __/  / /   / __ \/ __ `/ ___/ __ `/ _ \/ ___/
//  / /___/ (__  ) /_   / /___/ / / / /_/ / /  / /_/ /  __(__  ) 
// /_____/_/____/\__/   \____/_/ /_/\__,_/_/   \__, /\___/____/  
//                                            /____/             

export function* watchListCharges() {
    yield takeLatest("LIST_CHARGES", trackListCharges);
}

function* trackListCharges(sagaData) {
    try {
        var response = yield call(callListCharges, sagaData.payload);

        if (response) {
            yield put({
                type: "LIST_CHARGES_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "LIST_CHARGES_ERROR",
            response: err
        });
    }
}

function callListCharges(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/auth/list-charges", {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}