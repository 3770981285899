import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DashboardLayout from '../layouts/dashboard-layout';

export default function Dashboard(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const detailProps = useSelector(state => state.statistics);

    useEffect(() => {
        dispatch({
            type: "DASHBOARD_STATISTICS",
            payload: {}
        });
    }, []);

    return (
        <DashboardLayout {...props}>
            <section>
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Dashboard  </h1>
                        <p className="is-size-5m6 mb-4">Welcome, {authProps?.firstname} {authProps?.lastname}</p>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="columns is-multiline">
                        <div className="column is-12">
                            <h2 className="is-size-4 has-text-weight-semibold mt-2">Usage Instructions</h2>
                        </div>

                        <div className="column is-4">
                            <div className="box">
                                <h2 className="is-size-5 has-text-weight-semibold mb-2">Step 1</h2>
                                <p className="mb-2">Choose a product that will be the trigger to upsell/cross-sell your products</p>
                                <button className="button is-primary" onClick={() => history.push('/products')}>Proceed to Step 1</button>
                            </div>
                        </div>
                        <div className="column is-4">
                            <div className="box">
                                <h2 className="is-size-5 has-text-weight-semibold mb-2">Step 2</h2>
                                <p className="mb-2">Create a rule to setup the upsell/cross-sell flow</p>
                                <button className="button is-primary" onClick={() => history.push('/rules')}>Proceed to Step 2</button>
                            </div>
                        </div>
                        <div className="column is-4">
                            <div className="box">
                                <h2 className="is-size-5 has-text-weight-semibold mb-2">Step 3</h2>
                                <p className="mb-2">Select the products and add the rules to them to complete the integration</p>
                                <button className="button is-primary" onClick={() => history.push('/products')}>Proceed to Step 3</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="column is-6">
                    <div className="columns is-multiline">
                        <div className="column is-12">
                            <h2 className="is-size-4 has-text-weight-semibold mt-2">Weekly Report</h2>
                        </div>
                        <div className="column is-6">
                            <div className="box has-background-blue-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                <div>
                                    <p className="title is-size-2">{detailProps?.weekly?.views.$numberDecimal}</p>
                                    <p className="subtitle is-size-6 has-text-weight-medium">Views</p>
                                </div>
                            </div>
                        </div>
                        <div className="column is-6">
                            <div className="box has-background-pink-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                <div>
                                    <p className="title is-size-2">{detailProps?.weekly?.added_to_cart.$numberDecimal}</p>
                                    <p className="subtitle is-size-6 has-text-weight-medium">Add to Cart</p>
                                </div>
                            </div>
                        </div>
                        <div className="column is-6">
                            <div className="box has-background-purple-light is-flex h-full is-justify-content-center is-align-items-center has-text-centered">
                                <div>
                                    <p className="title is-size-2">{detailProps?.weekly?.conversions.$numberDecimal}</p>
                                    <p className="subtitle is-size-6 has-text-weight-medium">Conversions</p>
                                </div>
                            </div>
                        </div>
                        <div className="column is-6">
                            <div className="box has-background-orange-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                <div>
                                    <p className="title is-size-2">{authProps?.shop_details?.money_format.replace('{{amount}}', detailProps?.weekly?.revenue.$numberDecimal)}</p>
                                    <p className="subtitle is-size-6 has-text-weight-medium">Revenue</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-6">
                    <div className="columns is-multiline">
                        <div className="column is-12">
                            <h2 className="is-size-4 has-text-weight-semibold mt-2">Monthly Report</h2>
                        </div>
                        <div className="column is-6">
                            <div className="box has-background-blue-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                <div>
                                    <p className="title is-size-2">{detailProps?.monthly?.views.$numberDecimal}</p>
                                    <p className="subtitle is-size-6 has-text-weight-medium">Views</p>
                                </div>
                            </div>
                        </div>
                        <div className="column is-6">
                            <div className="box has-background-pink-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                <div>
                                    <p className="title is-size-2">{detailProps?.monthly?.added_to_cart.$numberDecimal}</p>
                                    <p className="subtitle is-size-6 has-text-weight-medium">Add to Cart</p>
                                </div>
                            </div>
                        </div>
                        <div className="column is-6">
                            <div className="box has-background-purple-light is-flex h-full is-justify-content-center is-align-items-center has-text-centered">
                                <div>
                                    <p className="title is-size-2">{detailProps?.monthly?.conversions.$numberDecimal}</p>
                                    <p className="subtitle is-size-6 has-text-weight-medium">Conversions</p>
                                </div>
                            </div>
                        </div>
                        <div className="column is-6">
                            <div className="box has-background-orange-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                <div>
                                    <p className="title is-size-2">{authProps?.shop_details?.money_format.replace('{{amount}}', detailProps?.monthly?.revenue.$numberDecimal)}</p>
                                    <p className="subtitle is-size-6 has-text-weight-medium">Revenue</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="column is-12">
                    <h2 className="is-size-3 has-text-weight-semibold">Performance</h2>
                </div>
                <div className="column is-6">
                    <h2 className="is-size-4 has-text-weight-semibold mb-5">By Views
                        <div className="is-size-6 has-text-grey pull-right mt-2">(Last 30 Days)</div>
                    </h2>
                    <div className="table-container">
                        <table className="table is-fullwidth">
                            {
                                !isEmpty(detailProps?.monthly?.by_views) && detailProps?.monthly?.by_views.map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{value.title}</td>
                                            <td><b>{value.views.$numberDecimal}</b></td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                    </div>
                </div>
                <div className="column is-6">
                    <h2 className="is-size-4 has-text-weight-semibold mb-5">By Add to Cart
                        <div className="is-size-6 has-text-grey pull-right mt-2">(Last 30 Days)</div>
                    </h2>
                    <div className="table-container">
                        <table className="table is-fullwidth">
                            {
                                !isEmpty(detailProps?.monthly?.by_views) && detailProps?.monthly?.by_views.map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{value.title}</td>
                                            <td><b>{value.added_to_cart.$numberDecimal}</b></td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                    </div>
                </div>
                <div className="column is-6">
                    <h2 className="is-size-4 has-text-weight-semibold mb-5">By Conversions
                        <div className="is-size-6 has-text-grey pull-right mt-2">(Last 30 Days)</div>
                    </h2>
                    <div className="table-container">
                        <table className="table is-fullwidth">
                            {
                                !isEmpty(detailProps?.monthly?.by_views) && detailProps?.monthly?.by_views.map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{value.title}</td>
                                            <td><b>{value.conversions.$numberDecimal}</b></td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                    </div>
                </div>
                <div className="column is-6">
                    <h2 className="is-size-4 has-text-weight-semibold mb-5"> By Revenue
                        <div className="is-size-6 has-text-grey pull-right mt-2"> (Last 30 Days) </div>
                    </h2>
                    <div className="table-container">
                        <table className="table is-fullwidth">
                            {
                                !isEmpty(detailProps?.monthly?.by_views) && detailProps?.monthly?.by_views.map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{value.title}</td>
                                            <td><b>{authProps?.shop_details?.money_format.replace('{{amount}}', value.revenue.$numberDecimal)}</b></td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                    </div>
                </div>
            </div>
        </DashboardLayout >
    )
}