import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { RestrictPages } from "../helpers";
import DashboardLayout from "../layouts/dashboard-layout";

export default function CartSettings(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const authProps = useSelector(state => state.auth);

    const [show1, setShow1] = useState('is-hidden');

    useEffect(() => {
        RestrictPages(history, authProps?.plan_details, 'ULTIMATE', '/dashboard', 'redirect');
    }, [])

    const schema = yup.object().shape({
        abandoned_cart: yup.object().shape({
            modal_title: yup.string().required(),
            modal_description: yup.string().required(),
            modal_form_label: yup.string().required(),
            cancel_button: yup.string().required(),
            confirm_button: yup.string().required(),
            success_message: yup.string().required(),
            style: yup.string()
        })
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (form) => {
        form.add_to_cart = authProps?.modal_data?.add_to_cart;
        form.before_checkout = authProps?.modal_data?.before_checkout;
        form.shopify_post_purchase = authProps?.modal_data?.shopify_post_purchase;

        console.log(form);

        dispatch({
            type: "UPDATE_RULE_SETTINGS",
            payload: form
        });

        reset();
    }

    return (
        <DashboardLayout {...props}>
            <section>
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Cart Settings </h1>
                        <p className="is-size-5m6 mb-4">Manage the popup title and design patterns for your Abandoned Cart popup module.</p>
                    </div>
                    <div>
                        <button className="button is-primary mr-2" onClick={() => history.push('/abandoned-cart')}>
                            <span className="icon is-small">
                                <i className="fa fa-chevron-left" aria-hidden="true"></i>
                            </span>
                            <span>Back</span>
                        </button>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline mt-2 mb-0">
                <div className="column is-12 pb-0">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="box">
                        <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                                <h2 className="is-size-5 has-text-weight-semibold has-text-primary">Settings - Abandoned Cart</h2>
                                <div>
                                    <input id="advanced-settings-1" type="checkbox" value="true" onChange={(e) => {
                                        if (e.target.checked) {
                                            setShow1('')
                                        } else {
                                            setShow1('is-hidden')
                                        }
                                    }} />
                                    <label htmlFor="advanced-settings-1" className="ml-1 has-text-weight-semibold">Show Advanced Settings</label>
                                </div>
                            </div>
                            <div className="columns is-multiline">
                                <div className="field column is-6 py-0 pt-5">
                                    <label className="label">Title</label>
                                    <input type="text" className="input" ref={register} name="abandoned_cart.modal_title" defaultValue={authProps?.modal_data?.abandoned_cart?.modal_title} />
                                    <p className="help is-danger">{errors?.abandoned_cart?.modal_title && "Button text is required"}</p>
                                </div>
                                <div className="field column is-6 py-0 pt-5">
                                    <label className="label">Description</label>
                                    <input type="text" className="input" ref={register} name="abandoned_cart.modal_description" defaultValue={authProps?.modal_data?.abandoned_cart?.modal_description} />
                                    <p className="help is-danger">{errors?.abandoned_cart?.modal_description && "Button text is required"}</p>
                                </div>
                                <div className="field column is-6 py-0">
                                    <label className="label">Form Label</label>
                                    <input type="text" className="input" ref={register} name="abandoned_cart.modal_form_label" defaultValue={authProps?.modal_data?.abandoned_cart?.modal_form_label} />
                                    <p className="help is-danger">{errors?.abandoned_cart?.modal_form_label && "Button text is required"}</p>
                                </div>
                                <div className="field column is-6 py-0">
                                    <label className="label">Cancel button text</label>
                                    <input type="text" className="input" ref={register} name="abandoned_cart.cancel_button" defaultValue={authProps?.modal_data?.abandoned_cart?.cancel_button} />
                                    <p className="help is-danger">{errors?.abandoned_cart?.cancel_button && "Button text is required"}</p>
                                </div>
                                <div className="field column is-6 py-0">
                                    <label className="label">Confirm button text</label>
                                    <input type="text" className="input" ref={register} name="abandoned_cart.confirm_button" defaultValue={authProps?.modal_data?.abandoned_cart?.confirm_button} />
                                    <p className="help is-danger">{errors?.abandoned_cart?.confirm_button && "Button text is required"}</p>
                                </div>
                                <div className="field column is-6 py-0">
                                    <label className="label">Success message</label>
                                    <input type="text" className="input" ref={register} name="abandoned_cart.success_message" defaultValue={authProps?.modal_data?.abandoned_cart?.success_message} />
                                    <p className="help is-danger">{errors?.abandoned_cart?.success_message && "Success Message is required"}</p>
                                </div>
                                <div className={`field column is-12 pt-0 ${show1}`}>
                                    <label className="label">Custom Styling</label>
                                    <textarea type="text" className="textarea" rows={8} ref={register} name="abandoned_cart.style" defaultValue={authProps?.modal_data?.abandoned_cart?.style}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="is-flex is-justify-content-flex-end mt-5">
                            <button className="button is-primary">Save Settings</button>
                        </div>
                    </form>
                </div>
            </div>
        </DashboardLayout>
    )
}