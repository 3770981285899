import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { RestrictPages } from "../helpers";
import DashboardLayout from "../layouts/dashboard-layout";
import Swal from "sweetalert2";

export default function EmailSettings(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const authProps = useSelector(state => state.auth);

    useEffect(() => {
        RestrictPages(history, authProps?.plan_details, 'ULTIMATE', '/dashboard', 'redirect');
    }, [])

    const schema = yup.object().shape({
        from_email: yup.string().required(),
        company: yup.string().required(),
        hostname: yup.string().required(),
        port: yup.string().required(),
        username: yup.string().required(),
        password: yup.string().required()
    });

    const { register, handleSubmit, errors, reset, getValues } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (form) => {
        dispatch({
            type: "UPDATE_EMAIL_SETTINGS",
            payload: form
        });

        reset();
    }

    const TriggerTestEmail = () => {
        let form = getValues();

        if(form.from_email === "" || form.company === "" || form.hostname === "" || form.port === "" || form.username === ""  || form.password === "") {
            Swal.fire({
                icon: "error",
                title: "Please add the email smtp details"
            });
        } else {
            dispatch({
                type: "TRIGGER_TEST_EMAIL",
                payload: form 
            });
        }
    }

    return (
        <DashboardLayout {...props}>
            <section>
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Email Settings </h1>
                        <p className="is-size-5m6 mb-4">Manage SMTP details for your mailing service of choice to send emails to the users that have abandoned cart on your store.</p>
                    </div>
                    <div>
                        <button className="button is-primary mr-2" onClick={() => history.push('/abandoned-cart')}>
                            <span className="icon is-small">
                                <i className="fa fa-chevron-left" aria-hidden="true"></i>
                            </span>
                            <span>Back</span>
                        </button>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline mt-2 mb-0">
                <div className="column is-12 pb-0">
                    <form>
                        <div className="box">
                            <div className="columns is-multiline">
                                <div className="field column is-6 py-0 pt-5">
                                    <label className="label">From Email</label>
                                    <input type="email" className="input" ref={register} name="from_email" defaultValue={authProps?.email_settings?.from_email} />
                                    <p className="help is-danger">{errors?.from_email && "Email is required"}</p>
                                </div>
                                <div className="field column is-6 py-0 pt-5">
                                    <label className="label">From Store</label>
                                    <input type="text" className="input" ref={register} name="company" defaultValue={authProps?.email_settings?.company} />
                                    <p className="help is-danger">{errors?.company && "Company/Store name is required"}</p>
                                </div>
                                <div className="field column is-6 py-0">
                                    <label className="label">Hostname</label>
                                    <input type="text" className="input" ref={register} name="hostname" defaultValue={authProps?.email_settings?.hostname} />
                                    <p className="help is-danger">{errors?.hostname && "Hostname is required"}</p>
                                </div>
                                <div className="field column is-6 py-0">
                                    <label className="label">Port</label>
                                    <input type="number" className="input" ref={register} name="port" defaultValue={authProps?.email_settings?.port} />
                                    <p className="help is-danger">{errors?.port && "Port is required"}</p>
                                </div>
                                <div className="field column is-6 py-0">
                                    <label className="label">Username</label>
                                    <input type="text" className="input" ref={register} name="username" defaultValue={authProps?.email_settings?.username} />
                                    <p className="help is-danger">{errors?.username && "Username is required"}</p>
                                </div>
                                <div className="field column is-6 py-0">
                                    <label className="label">Password</label>
                                    <input type="text" className="input" ref={register} name="password" defaultValue={authProps?.email_settings?.password} />
                                    <p className="help is-danger">{errors?.password && "Password is required"}</p>
                                </div>
                            </div>
                        </div>
                        <div className="is-flex is-justify-content-space-between mt-5">
                            <a className="button is-primary" onClick={() => TriggerTestEmail()}>Test Email</a>
                            <button className="button is-primary" onClick={handleSubmit(onSubmit)}>Save Settings</button>
                        </div>
                    </form>
                </div>
            </div>
        </DashboardLayout>
    )
}