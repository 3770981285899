import React, { useContext } from "react";
import { Loader, LoaderContext } from "../loader";

import Header from "../components/header";
import Sidebar from "../components/sidebar";

function DashboardLayout(props) {
    const { loading } = useContext(LoaderContext);

    return (
        <React.Fragment>
            <Sidebar />
            <Header />
            <Loader loading={loading} />
            <div className="page-wrapper p-5">
                {props.children}
            </div>
        </React.Fragment>
    )
}

export default DashboardLayout;