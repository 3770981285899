import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty, find } from "lodash";
import moment from "moment";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";

import { GetID, RestrictPages } from "../helpers";
import { LoaderContext } from "../loader";
import DashboardLayout from "../layouts/dashboard-layout";

export default function Products(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { setLoading } = useContext(LoaderContext);
    const authProps = useSelector(state => state.auth);
    const searchProps = useSelector(state => state.search);
    const productProps = useSelector(state => state.products);
    const collectionProps = useSelector(state => state.collections);
    const ruleProps = useSelector(state => state.rules);

    const [searchProducts, setSearchProducts] = useState({});
    const [products, setProducts] = useState({});
    const [collections, setCollections] = useState({});
    const [rules, setRules] = useState({});
    const [modal, setModal] = useState('');
    const [collectionModal, setCollectionModal] = useState('');

    const limit = 50;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        RestrictPages(history, authProps?.plan_details, 'FREE', '/dashboard', 'redirect');

        FetchProducts();

        dispatch({
            type: "FETCH_RULES",
            payload: {
                page: 0,
                limit: 200
            }
        });

        dispatch({
            type: "LIST_COLLECTIONS",
            payload: {}
        });
    }, []);

    useEffect(() => {
        FetchProducts();
    }, [page]);

    useEffect(() => {
        if (!isEmpty(searchProps)) {
            setSearchProducts(searchProps);
        }
    }, [searchProps]);

    useEffect(() => {
        if (!isEmpty(ruleProps)) {
            setRules(ruleProps.results);

            dispatch({
                type: "SEARCH_PRODUCTS",
                payload: {}
            });
        }
    }, [ruleProps]);

    useEffect(() => {
        if (productProps?.results === undefined) {
            setProducts({});
            setTotal(0)
        } else {
            setProducts(productProps.results);
            setTotal(productProps.total)
        }
    }, [productProps]);

    useEffect(() => {
        if (!isEmpty(collectionProps)) {
            setCollections(collectionProps.collections);
        }
    }, [collectionProps]);

    const FetchProducts = () => {
        dispatch({
            type: "FETCH_PRODUCTS",
            payload: {
                page: page - 1,
                limit: limit
            }
        });
    }

    const SearchProducts = (e) => {
        let searchString = e.target.value.trim();

        if (searchString.length >= 3) {
            dispatch({
                type: "SEARCH_PRODUCTS",
                payload: {
                    search: searchString
                }
            });
        }
    }

    const SelectProducts = () => {
        setLoading(true);
        setModal('');

        let products = document.getElementsByClassName('product_id');
        let selectedProducts = [];

        for (let i = 0; i < products.length; i++) {
            if (products[i].checked == true) {
                let matchedProduct = find(searchProducts, function (o) {
                    return o.node.id == products[i].value;
                });

                let data = {
                    product_id: matchedProduct.node.id,
                    title: matchedProduct.node.title,
                    image: matchedProduct.node.featuredImage.url
                }

                selectedProducts.push(data);
                products[i].checked = false;
            }
        }

        dispatch({
            type: "ADD_PRODUCTS",
            payload: selectedProducts
        });

        setTimeout(() => {
            FetchProducts();
            setLoading(false);
        }, 2000);
    }

    const DeleteProduct = (product_id) => {
        Swal.fire({
            title: 'Are you sure?',
            html: 'This will permanently remove the product?',
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "OK"
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);

                dispatch({
                    type: "REMOVE_PRODUCT",
                    payload: {
                        product_id: product_id
                    }
                });

                setTimeout(() => {
                    FetchProducts();
                    setLoading(false);
                }, 1000);
            }
        });
    }

    const AddProductsToRule = () => {
        setLoading(true);
        let selected_rule = document.getElementsByName('select_rule')[0];
        let selected_products = document.getElementsByName('select_products');

        if (selected_rule.value === "Select Rule") {
            setLoading(false);
            Swal.fire({
                icon: "error",
                title: "Please select a rule first"
            });
        } else {
            let product_ids = [];
            for (let i = 0; i < selected_products.length; i++) {
                if (selected_products[i].checked === true) {
                    product_ids.push(selected_products[i].value);
                    selected_products[i].checked = false;
                }
            }

            if (product_ids.length > 0) {
                dispatch({
                    type: "ADD_RULE_TO_PRODUCTS",
                    payload: {
                        rule_id: selected_rule.value,
                        product_ids: product_ids
                    }
                });

                setTimeout(() => {
                    setLoading(false);
                    FetchProducts();
                }, 3000);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Please select some products to be added to the rule"
                });
            }
        }
    }

    const AddCollectionsToRule = () => {
        setLoading(true);
        let selected_rule = document.getElementsByName('select_rule')[0];
        let selected_collections = document.getElementsByClassName('select_collection');

        if (selected_rule.value === "Select Rule") {
            setLoading(false);
            Swal.fire({
                icon: "error",
                title: "Please select a rule first"
            });
        } else {
            let collection_ids = [];
            for (let i = 0; i < selected_collections.length; i++) {
                if (selected_collections[i].checked === true) {
                    collection_ids.push(selected_collections[i].value);
                    selected_collections[i].checked = false;
                }
            }
            
            if (collection_ids.length > 0) {
                dispatch({
                    type: "ADD_RULE_TO_COLLECTIONS",
                    payload: {
                        rule_id: selected_rule.value,
                        collection_ids: collection_ids
                    }
                });
                
                setCollectionModal('');
                
                setTimeout(() => {
                    FetchProducts();
                    setLoading(false);
                }, 1000);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Please select a collection to be added to the rule"
                });
            }
        }
    }

    return (
        <DashboardLayout {...props}>
            <section>
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Products </h1>
                        <p className="is-size-5m6 mb-4">Modify your products to also have rules. You can only add one rule per product.</p>
                    </div>
                </div>
            </section>

            <section>
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <div className="select mr-2">
                            <select name="select_rule">
                                <option>Select Rule</option>
                                {
                                    !isEmpty(rules) && Object.values(rules).map((rule, index) => {
                                        return (
                                            <option value={rule?._id} key={index + 1}>{rule?.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <button className="button is-primary mt-1" onClick={() => AddProductsToRule()}> Add Rule to Selected Products </button>
                        <button className="button is-primary mt-1 ml-1" onClick={() => setCollectionModal('is-active')}> Add Rule to a Collection </button>
                    </div>
                    <div>
                        <button className="button is-primary" onClick={() => setModal('is-active')}> Add Products </button>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline mt-2 mb-0">
                <div className="column is-12 pb-0">
                    <div className="box table-container">
                        <table className="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>Product</th>
                                    <th>Rule</th>
                                    <th>Created At</th>
                                    <th>Updated At</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !isEmpty(products) && Object.values(products).map((product, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><input type="checkbox" name="select_products" value={product?.product_id} /></td>
                                                <td><img className="image is-64x64 border border-solid border-color-white-bis" src={product.image} /></td>
                                                <td>{product.title}</td>
                                                <td>{product?.rule.length === 0 ? "N/A" : <a className="has-text-weight-semibold" onClick={() => history.push(`/rules/${GetID(product?.rule[0]?._id)}`)}>{product?.rule[0]?.name}</a>}</td>
                                                <td>{moment(product.created_at).format("DD MMM, YYYY")}</td>
                                                <td>{moment(product.updated_at).format("DD MMM, YYYY")}</td>
                                                <td>
                                                    <button className="button is-danger is-small mr-1" onClick={() => DeleteProduct(product.product_id)}>Delete</button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {
                            isEmpty(products) ? <p className="pt-0 pb-5 has-text-centered is-fullwidth box-content">No products found</p> : ""
                        }
                    </div>
                </div>
            </div>

            <div className="is-flex is-align-items-center is-justify-content-space-between mt-4">
                <div>Showing <b>{products.length}</b> of <b>{total}</b> products</div>
                <Pagination activePage={page} itemsCountPerPage={limit} totalItemsCount={total} pageRangeDisplayed={5} onChange={(e) => setPage(e)} />
            </div>

            <div className={`modal ${modal}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title is-size-5 has-text-weight-semibold">Add Product</p>
                        <a className="delete" aria-label="close" onClick={() => setModal('')}>x</a>
                    </header>
                    <section className="modal-card-body py-0">
                        <div className="field">
                            <label className="is-size-5m6 has-text-weight-semibold">Search Product</label>
                            <input type="search" className="input" name="search" onChange={(e) => SearchProducts(e)} placeholder="Search for your products on your store" />
                        </div>
                        <div className="table-container" style={{ "overflowY": "scroll", "height": "400px" }}>
                            <table className="table w-full">
                                <tbody>
                                    {
                                        !isEmpty(searchProducts) && Object.values(searchProducts).map((product, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><input type="checkbox" className="product_id" name={`product_id_${GetID(product?.node?.id)}`} value={product?.node?.id} /></td>
                                                    <td><img className="image is-48x48" src={product?.node?.featuredImage?.url} /></td>
                                                    <td>{product?.node?.title}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <footer className="modal-card-foot is-justify-content-flex-end">
                        <a className="button has-background-white-bis border border-sold border-color-white-bis has-text-dark" onClick={() => setModal('')}>Cancel</a>
                        <button className="button is-primary" type="submit" onClick={() => SelectProducts()}>Submit</button>
                    </footer>
                </div>
            </div>

            <div className={`modal ${collectionModal}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title is-size-5 has-text-weight-semibold">Select Collection</p>
                        <a className="delete" aria-label="close" onClick={() => setCollectionModal('')}>x</a>
                    </header>
                    <section className="modal-card-body py-0">
                        <p className="has-text-weiight-semibold mb-2">Select a collection to add the selected rule to all its products.</p>
                        <div className="table-container" style={{ "overflowY": "scroll", "height": "400px" }}>
                            <table className="table w-full">
                                <thead>
                                    <th>#</th>
                                    <th>Collection Title</th>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(collections) && Object.values(collections).map((collection, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><input type="checkbox" className="select_collection" name={`collection_id_${collection?.id}`} value={collection?.id} /></td>
                                                    <td>{collection?.title}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <footer className="modal-card-foot is-justify-content-flex-end">
                        <a className="button has-background-white-bis border border-sold border-color-white-bis has-text-dark" onClick={() => setCollectionModal('')}>Cancel</a>
                        <button className="button is-primary" type="submit" onClick={() => AddCollectionsToRule()}>Submit</button>
                    </footer>
                </div>
            </div>
        </DashboardLayout>
    )
}