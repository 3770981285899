import Swal from "sweetalert2";

//    _____ __        __  _         ____        __       
//   / ___// /_____ _/ /_(_)____   / __ \____ _/ /_____ _
//   \__ \/ __/ __ `/ __/ / ___/  / / / / __ `/ __/ __ `/
//  ___/ / /_/ /_/ / /_/ / /__   / /_/ / /_/ / /_/ /_/ / 
// /____/\__/\__,_/\__/_/\___/  /_____/\__,_/\__/\__,_/  

const ListMonths = () => {
    let months = [
        {
            name: "January",
            value: 1
        },
        {
            name: "February",
            value: 2
        },
        {
            name: "March",
            value: 3
        },
        {
            name: "April",
            value: 4
        },
        {
            name: "May",
            value: 5
        },
        {
            name: "June",
            value: 6
        },
        {
            name: "July",
            value: 7
        },
        {
            name: "August",
            value: 8
        },
        {
            name: "September",
            value: 9
        },
        {
            name: "October",
            value: 10
        },
        {
            name: "November",
            value: 11
        },
        {
            name: "December",
            value: 12
        }
    ];

    return months;
}

const ListYears = () => {
    var currentYear = new Date().getFullYear();
    var years = [];

    for (let i = 0; i <= 15; i++) {
        years[i] = currentYear;
        currentYear = currentYear + 1;
    }

    return years;
}

//    _____ __
//   / ___// /_____  _________ _____ ____
//   \__ \/ __/ __ \/ ___/ __ `/ __ `/ _ \
//  ___/ / /_/ /_/ / /  / /_/ / /_/ /  __/
// /____/\__/\____/_/   \__,_/\__, /\___/
//                           /____/

const AddToStorage = (elem, value) => {
    sessionStorage.setItem(elem, value);
}

const RemoveFromStorage = (elem) => {
    sessionStorage.removeItem(elem);
}

const FetchFromStorage = (elem) => {
    return sessionStorage.getItem(elem);
}

//    _____ __        __          __  ___                                                  __
//   / ___// /_____ _/ /____     /  |/  /___ _____  ____ _____ ____  ____ ___  ___  ____  / /_
//   \__ \/ __/ __ `/ __/ _ \   / /|_/ / __ `/ __ \/ __ `/ __ `/ _ \/ __ `__ \/ _ \/ __ \/ __/
//  ___/ / /_/ /_/ / /_/  __/  / /  / / /_/ / / / / /_/ / /_/ /  __/ / / / / /  __/ / / / /_
// /____/\__/\__,_/\__/\___/  /_/  /_/\__,_/_/ /_/\__,_/\__, /\___/_/ /_/ /_/\___/_/ /_/\__/
//                                                     /____/

const LoadState = () => {
    try {
        const serializedData = sessionStorage.getItem('state');
        if (serializedData == null) {
            return undefined;
        }
        return JSON.parse(serializedData);
    } catch (err) {
        return undefined;
    }
}

const SaveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem('state', serializedState);
    } catch (err) {
        // Ignore write errors
    }
}

//     ____  _                __     __  __     __                    
//    / __ \(_)_______  _____/ /_   / / / /__  / /___  ___  __________
//   / / / / / ___/ _ \/ ___/ __/  / /_/ / _ \/ / __ \/ _ \/ ___/ ___/
//  / /_/ / / /  /  __/ /__/ /_   / __  /  __/ / /_/ /  __/ /  (__  ) 
// /_____/_/_/   \___/\___/\__/  /_/ /_/\___/_/ .___/\___/_/  /____/  
//                                           /_/                      

export const GetID = (gid) => {
    if (gid === undefined) {
        return '';
    } else {
        let splitGID = gid.split('/');
        let count = splitGID.length;

        return splitGID[count - 1];
    }
}

export const RestrictPages = (history, condition, match, url, type) => {
    if (condition.name === match || condition.name === "ULTIMATE") {
        if (type === "popup") {
            history.push(url);
        }
    } else {
        if (type === "popup") {
            Swal.fire({
                icon: "error",
                title: `Please choose the ${match} plan to unlock this feature`
            });
        } else if (type === "redirect") {
            history.goBack();
        }
    }

    return true;
}

//     ______                      __     __  __     __
//    / ____/  ______  ____  _____/ /_   / / / /__  / /___  ___  __________
//   / __/ | |/_/ __ \/ __ \/ ___/ __/  / /_/ / _ \/ / __ \/ _ \/ ___/ ___/
//  / /____>  </ / _ / / /_ / / / / /_   / __ / __ / / /_ / /  __/ / (__)
// /_____/_/|_/ .___/\____/_/   \__/  /_/ /_/\___/_/ .___/\___/_/  /____/
//           /_/                                  /_/

export const Storage = {
    "Add": AddToStorage,
    "Remove": RemoveFromStorage,
    "Fetch": FetchFromStorage
}

export const State = {
    "Save": SaveState,
    "Load": LoadState
}

export const StaticData = {
    "Months": ListMonths,
    "Years": ListYears
}