import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import Swal from "sweetalert2";

import { RestrictPages } from "../helpers";
import { LoaderContext } from "../loader"
import DashboardLayout from "../layouts/dashboard-layout";

export default function AbandonedCart(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const authProps = useSelector(state => state.auth);
    const cartProps = useSelector(state => state.carts);
    const [carts, setCarts] = useState({});

    const { setLoading } = useContext(LoaderContext);

    useEffect(() => {
        RestrictPages(history, authProps?.plan_details, 'ULTIMATE', '/dashboard', 'redirect');

        FetchCarts();
    }, []);

    useEffect(() => {
        if (!isEmpty(cartProps)) {
            setCarts(cartProps);
        }
    }, [cartProps]);

    const FetchCarts = () => {
        dispatch({
            type: "LIST_ABANDONED_CART",
            payload: {}
        });
    }

    const DeleteCart = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            html: 'This will permanently remove the abandoned cart data.',
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "OK"
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);

                dispatch({
                    type: "REMOVE_ABANDONED_CART",
                    payload: {
                        id: id
                    }
                });

                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            }
        });
    }

    const SendEmailToUser = (data) => {
        var cart_items = "";

        for (let i = 0; i < data.items.length; i++) {
            cart_items = cart_items + `${data.items[i].variant_id}:${data.items[i].quantity},`
        }

        let permalink = `https://${authProps?.shop}/cart/${cart_items}?checkout[email]=${data.email}`;

        dispatch({
            type: "TRIGGER_USER_EMAIL",
            payload: {
                permalink: permalink,
                cart: data
            }
        })
    }

    return (
        <DashboardLayout {...props}>
            <section>
                <div className="is-md-flex is-align-items-center is-justify-content-space-between">
                    <div>
                        <h1 className="title is-size-4 is-size-4-tablet mb-2 has-text-primary"> Carts </h1>
                        <p className="is-size-5m6 mb-4">Emails captured by Smart Upsell through abandoned cart pop-up.</p>
                    </div>
                    <div>
                        <button className="button is-primary with-shadow mr-2" onClick={() => history.push('/email-settings')}>Email Settings</button>
                        <button className="button is-primary with-shadow mr-2" onClick={() => history.push('/cart-settings')}>Cart Settings</button>
                    </div>
                </div>
            </section>

            <div className="columns is-multiline mt-2 mb-0">
                <div className="column is-12 pb-0">
                    <div className="box table-container">
                        <table className="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Email</th>
                                    <th width="60%">Item Data</th>
                                    <th width="10%">Cart Total</th>
                                    <th width="12%">Created On</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !isEmpty(carts) && Object.values(carts).map((cart, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{cart?.email}</td>
                                                <td>
                                                    {
                                                        Object.values(cart.items).map((item, index2) => {
                                                            return (
                                                                <div className="mt-4" key={index2}>
                                                                    <p><b>Product Title :</b> {item.product_title}</p>
                                                                    <p><b>Product SKU :</b> {item.sku}</p>
                                                                    <p><b>Product Price :</b> {item.final_price / 100} <b>{cart.currency}</b></p>
                                                                    <p><b>Product Quantity :</b> {item.quantity}</p>
                                                                    <p><b>Total Price :</b> {item.final_line_price / 100} <b>{cart.currency}</b></p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </td>
                                                <td>{cart?.total_price / 100} <b>{cart?.currency}</b></td>
                                                <td>{moment(cart?.created_at).format('DD MMM, YYYY')}</td>
                                                <td>
                                                    <button className="button is-success">Alert User</button>
                                                    <button className="button is-danger mt-1" onClick={() => DeleteCart(cart?._id)}>Delete Cart</button>
                                                    <a className="button is-info mt-1" href={SendEmailToUser(cart)} target="_blank" rel="noopenner norefferer">Email User and add items to his/her Cart</a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {
                            isEmpty(carts) ? <p className="pt-0 pb-5 has-text-centered is-fullwidth box-content">No Records found</p> : ""
                        }
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}